.accordion-v2-section {
  padding: ac(85px, 57px) 0;

  overflow: hidden;
}

.accordion-v2__item {
  border: 1px solid transparent;
  border-radius: 16px;

  &:not(:last-child) {
    margin-bottom: 6px;
  }

  transition: setTransition(border, padding);

  &.active {
    border-color: var(--light-gray);
    padding-bottom: ac(52px, 25px);

    .accordion-v2__item-content-wrap {
      opacity: 1;
      visibility: visible;
      max-height: 1000px;
    }

    .accordion-v2__item-title-wrap {
      padding-left: ac(56px, 34px);
      padding-top: ac(70px, 23px);
      padding-bottom: 20px;

      background: var(--white);
    }

    .accordion-v2__item-title {
      color: var(--magenta);

      @mixin min-media 769 {
        font-size: 38px;
      }
    }

    .accordion-v2__item-btn {
      border-color: var(--gray);

      &::after {
        opacity: 0;
      }
    }
  }
}

.accordion-v2__item-title-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: ac(32px, 20px) ac(32px, 22px);
  border-radius: 16px;

  background: var(--light-blue);

  cursor: pointer;

  transition: setTransition(padding, background);

  &:hover {
    .accordion-v2__item-btn {
      &::before,
      &::after {
        background: var(--magenta);
      }
    }
  }

  @mixin media 551 {
    padding: 25px 20px 25px 34px;
  }
}

.accordion-v2__item-text {
  display: flex;
  align-items: center;

  margin-right: 10px;

  @mixin media 769 {
    line-height: 1.2;
  }
}

.accordion-v2__item-num {
  padding-right: ac(16px, 12px);
  margin-right: ac(24px, 16px);
  border-right: 1px solid var(--light-gray);

  color: var(--gray);

  font-size: ac(28px, 16px);
  line-height: 1.44;
}

.accordion-v2__item-title {
  font-size: ac(36px, 22px);
  font-weight: 700;

  transition: setTransition(color);
}

.accordion-v2__item-btn {
  position: relative;

  flex-shrink: 0;

  width: ac(48px, 30px);
  height: ac(48px, 30px);

  border: 2px solid var(--magenta);

  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);

  transition: setTransition(border);

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    border-radius: 10px;

    background: var(--black);

    transition: setTransition(background, opacity);
  }

  &::before {
    width: ac(24px, 16px);
    height: 2px;
  }

  &::after {
    width: 2px;
    height: ac(24px, 16px);
  }
}

.accordion-v2__item-content-wrap {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  visibility: hidden;

  display: flex;

  padding-left: ac(56px, 25px);

  transition: setTransition(max-height, opacity, visibility);

  @mixin media 769 {
    flex-direction: column;

    padding: 0;
  }
}

.accordion-v2__item-img-wrap {
  flex-grow: 1;

  width: 100%;
  max-width: 650px;
  max-height: 470px;

  margin-right: ac(48px, 20px);

  @mixin media 769 {
    height: 185px;
    margin-right: 0;
    margin-bottom: 13px;
  }
}

.accordion-v2__item-img {
  width: 100%;
  height: 100%;
  object-fit: cover;

  border-radius: 16px;

  @mixin media 769 {
    border-radius: 8px;
  }
}

.accordion-v2__item-point-list {
  display: grid;
  flex-grow: 1;

  width: 100%;
  max-width: 544px;

  @mixin media 769 {
    max-width: unset;

    padding: 0 25px;
  }
}

.accordion-v2__item-point {
  &:not(:last-child) {
    border-bottom: 1px solid var(--light-gray);
    margin-bottom: ac(16px, 8px);
  }
}

.accordion-v2__item-point-link {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: ac(24px, 8.5px) ac(12px, 0px) ac(24px, 8.5px) 0;

  font-size: ac(24px, 16px);
  font-weight: 700;
  line-height: 1.34;

  transition: setTransition(color);

  &:hover {
    color: var(--magenta);

    span {
      transform: translateX(15px);
    }
  }

  i {
    font-size: ac(24px, 14px);

    background: var(--magenta-gr);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  span {
    transition: setTransition(transform);
  }
}

.ask-client {
  padding-top: 51px;

  .cont {
    overflow: hidden;
  }
}

.ask-client__title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: ac(64px, 23px);

  .section__title {
    width: fit-content;

    margin-bottom: 0;
  }
}

.ask-client__title {
  margin-right: 20px;
}

.ask-client-swiper-logo__wrap {
  position: relative;

  clip-path: inset(0 3px 0 0);

  @mixin media 551 {
    /* clip-path: none; */
  }

  &::after {
    content: "";
    position: absolute;
    right: -1px;
    top: 0;
    bottom: 0;

    width: 100px;

    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );

    pointer-events: none;

    @mixin media 551 {
      /* display: none; */
    }
  }
}

.ask-client-swiper-logo {
  width: ac(236px, 132px);

  margin-bottom: ac(32px, 16px);

  @mixin media 551 {
    /* width: 100%; */
  }

  .swiper-slide {
    /* width: 236px; */

    transition-property: transform, opacity, height, margin;
  }

  .swiper-slide-active {
    margin-right: 36px;

    @mixin media 551 {
      margin-right: 0;
    }

    .ask-client-swiper-logo__card {
      transform: scale(1);

      &::before {
        opacity: 1;
      }
    }

    .ask-client-swiper-logo__card-logo {
      filter: grayscale(1) opacity(1) brightness(0) invert(1);
    }
  }
}

.ask-client-swiper-logo__card {
  position: relative;
  overflow: hidden;

  transform: scale(0.71);

  display: flex;
  justify-content: center;
  align-items: center;

  height: ac(236px, 132px);

  border: 1px solid var(--light-gray);
  border-radius: 8px;

  background: var(--white);

  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.06);

  transition: box-shadow 0.6s, opacity 0.6s, transform 0.6s !important;

  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    display: block;

    background: linear-gradient(310.83deg, #2afacf 16.95%, #2f4596 109.17%);

    opacity: 0;

    transition: setTransition(600ms, opacity);
  }
}

.ask-client-swiper-logo__card-logo {
  position: relative;
  z-index: 1;

  max-width: ac(110px, 60px);
  max-height: ac(60px, 33px);
  width: 100%;
  height: 100%;
  object-fit: contain;

  filter: grayscale(1) opacity(0.7);

  transition: setTransition(600ms, filter);

  @mixin media 551 {
    /* max-width: 190px;
		max-height: 100px; */
  }
}

.ask-client__testimonial-swiper-wrap {
  position: relative;
  overflow: hidden;

  padding-top: ac(56px, 16px);
  padding-bottom: ac(40px, 30px);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    height: 267px;

    border-radius: 16px 16px 4px 4px;

    background: linear-gradient(310.83deg, #2afacf 16.95%, #2f4596 109.17%);
  }
}

.ask-client-testimonial-swiper {
  position: relative;
  overflow: hidden;

  width: 90%;
  max-width: 862px;

  margin: 0 auto;
  padding: ac(80px, 33px) ac(86px, 24px) ac(40px, 30px);
  border-radius: 16px;

  background: var(--white);
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.12);

  @mixin media 769 {
    padding-bottom: 52px;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;

    width: ac(107px, 70px);
    height: ac(95px, 62px);

    display: block;

    background-size: contain;
    background-image: url("/images/quotes.svg");
    background-repeat: no-repeat;
  }

  &::before {
    left: ac(-8px, -2px);
    top: ac(-16px, -6px);

    transform: rotate(180deg);
  }

  &::after {
    right: ac(-8px, -2px);
    bottom: ac(-16px, -6px);
  }

  .swiper-wrapper {
    margin-bottom: ac(40px, 36px);
  }

  .swiper-slide {
    overflow: visible !important;

    height: auto;
  }
}

.ask-client__testimonial {
  display: flex;
  flex-direction: column;

  height: 100%;
}

.ask-client__testimonial-text {
  max-height: 160px;

  margin-bottom: auto;

  font-size: ac(20px, 16px);
  line-height: 1.6;
  text-align: center;

  * {
    font-size: ac(20px, 16px);
    line-height: 1.6;
    text-align: center;
  }

  @mixin media 769 {
    max-height: 315px;

    line-height: 1.64;

    * {
      line-height: 1.64;
    }
  }
}

.ask-client__testimonial-name {
  margin-top: ac(40px, 18px);
  margin-bottom: 6px;

  font-size: ac(24px, 22px);
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
}

.ask-client__testimonial-position {
  width: fit-content;

  margin: 0 auto;

  background: var(--magenta-gr);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: ac(20px, 16px);
  text-align: center;
}

.ask-client-testimonial-swiper__nav {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ask-client-testimonial-swiper__nav-btn {
  width: ac(56px, 30px);
  height: ac(56px, 30px);

  color: var(--gray);

  line-height: 1;

  transition: setTransition(color);

  i {
    font-size: 24.5px;
  }

  &:hover {
    color: var(--magenta);
  }
}

.ask-client-testimonial-swiper__slide-count {
  display: flex;
  justify-content: center;

  width: 5ch;

  margin: 0 18.5px;

  color: var(--gray);

  font-size: 20px;

  @mixin media 769 {
    margin: 0 31px;
  }

  .swiper-pagination-total {
    color: var(--black);
  }
}
