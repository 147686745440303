.input,
.textarea {
  width: 100%;
  height: 100%;
  min-height: 40px;

  padding-left: 20px;
  border-bottom: 1px solid var(--magenta);
  border-radius: 0;

  background: transparent;

  font-size: ac(16px, 14px);
  resize: none;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: var(--black) !important;
  }

  &::placeholder {
    color: var(--gray);
  }
}

.textarea {
  display: flex;
  /* height: 75px; */
  overflow: auto;
  min-height: 40px;
  max-height: 134px;

  padding-top: 7px;

  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    padding: 5px 0;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  &.scrollbar-is-show {
    &::-webkit-scrollbar-thumb {
      background: var(--light-gray);
    }
  }
}

.select2-container {
  width: 100% !important;
  max-width: 350px;
  /* height: 100%; */
  min-height: 40px;
}

.select2-selection {
  display: flex !important;
  align-items: center;

  height: 100% !important;
  min-height: 40px;

  padding-left: 12px;
  border: none !important;
  border-bottom: 1px solid var(--magenta) !important;
  border-radius: 0px !important;

  background: transparent !important;
}

.select2-selection__rendered {
  font-size: ac(16px, 14px);
}

.select2-container--open {
  .select2-selection__arrow {
    &::before {
      transform: rotate(180deg);
    }
  }
}

.select2-selection__placeholder {
  color: var(--gray) !important;
  font-size: ac(16px, 14px) !important;
}

.select2-selection__arrow {
  top: 50% !important;
  right: 10px !important;
  transform: translateY(-50%);

  display: flex;
  justify-content: center;
  align-items: center;

  height: 20px !important;

  b {
    display: none;
  }

  &::before {
    content: "\e910";
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
  }
}

.select2-dropdown {
  border: 1px solid var(--magenta);
}

.select2-results__options {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--magenta);
    border-radius: 50px;
  }
}

.select2-results__option--selected {
  background: var(--light-gray) !important;
}

.select2-results__option--highlighted {
  background: var(--magenta-gr) !important;
}

.upload-input {
  cursor: pointer;

  .upload-input__title {
    display: block;

    margin-bottom: 8px;
    padding-left: 20px;

    font-size: ac(16px, 14px);
  }

  .upload-input__file-name {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 14px;
    /* border-radius: 4px; */
    /* border: 1px solid var(--magenta); */

    font-size: ac(16px, 14px);
    font-weight: 700;

    transition: setTransition(color, background);

    &:hover {
      /* background: var(--magenta); */
      color: var(--white);

      &::before {
        opacity: 1;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      opacity: 0;

      border-radius: 4px;

      background: var(--magenta-gr);

      transition: setTransition(opacity);
    }

    &::after {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 4px;
      padding: 1px;
      z-index: -1;
      background: var(--magenta-gr);
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
  }

  .upload-input__file {
    display: none;
  }
}
