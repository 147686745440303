@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?qe9vq5");
  src: url("../fonts/icomoon.eot?qe9vq5#iefix") format("embedded-opentype"),
    url("../fonts/icomoon.woff2?qe9vq5") format("woff2"),
    url("../fonts/icomoon.ttf?qe9vq5") format("truetype"),
    url("../fonts/icomoon.woff?qe9vq5") format("woff"),
    url("../fonts/icomoon.svg?qe9vq5#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\e917";
}
.icon-twitter:before {
  content: "\e915";
}
.icon-facebook:before {
  content: "\e916";
}
.icon-location:before {
  content: "\e912";
}
.icon-job-type:before {
  content: "\e913";
}
.icon-money:before {
  content: "\e914";
}
.icon-arrow-right-down:before {
  content: "\e911";
}
.icon-dropdown-arrow:before {
  content: "\e910";
}
.icon-search:before {
  content: "\e900";
}
.icon-plus:before {
  content: "\e901";
}
.icon-arrow-right:before {
  content: "\e902";
}
.icon-arrow-left:before {
  content: "\e903";
}
.icon-listen:before {
  content: "\e904";
}
.icon-news:before {
  content: "\e905";
}
.icon-calendar:before {
  content: "\e906";
}
.icon-hub:before {
  content: "\e907";
}
.icon-pin:before {
  content: "\e908";
}
.icon-build:before {
  content: "\e909";
}
.icon-phone:before {
  content: "\e90a";
}
.icon-mail:before {
  content: "\e90b";
}
.icon-linkedin:before {
  content: "\e90c";
}
.icon-tiktok:before {
  content: "\e90d";
}
.icon-instagram:before {
  content: "\e90e";
}
.icon-youtube:before {
  content: "\e90f";
}

* {
  font-family: var(--font-main);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  font-family: var(--font-main);
  color: var(--black);
}

h1 {
  font-size: ac(72px, 42px);
  font-weight: 700;

  line-height: 1.19;

  margin-bottom: ac(18px, 10px);
}

h2 {
  font-size: ac(62px, 36px);
  font-weight: 700;

  line-height: 130%;

  margin-bottom: 14px;
}

h3 {
  font-size: ac(46px, 26px);
  font-weight: 700;
}

h4 {
  font-size: ac(32px, 20px);
  font-weight: 700;

  line-height: 130%;

  margin-bottom: ac(20px, 16px);
}

h5 {
  font-size: ac(26px, 20px);
  font-weight: 700;

  line-height: 1.5;

  margin-bottom: ac(18px, 10px);
}

h6 {
  font-size: ac(22px, 18px);
  font-weight: 700;

  line-height: 2;
}

p {
  font-size: ac(16px, 14px);

  line-height: 1.6;

  /* padding-bottom: ac(25px, 10px); */

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

a {
  font-size: ac(16px, 14px);
  font-weight: 500;
  display: inline-block;

  line-height: 1.5;
}

.content-element {
  p {
    font-size: 16px;
    line-height: 1.635;

    &:not(:last-child) {
      margin-bottom: 0.7rem;
    }
  }

  h2 {
    font-size: ac(42px, 30px);
  }
  h3 {
    font-size: ac(36px, 26px);
  }

  h5 {
    font-size: ac(24px, 22px);
  }

  h6 {
    font-size: 22px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:not(:last-child) {
      margin-bottom: ac(21px, 19px);
    }

    &:not(:first-child) {
      margin-top: ac(43px, 20px);
    }

    @mixin media 769 {
      line-height: 1.34;
      br {
        display: none;
      }
    }
  }

  img {
    width: 100%;
    /* max-width: 600px; */

    border-radius: 16px;
    margin: 32px auto;
  }

  ol {
    list-style: decimal inside;
    font-size: 16px;
    line-height: 1.6;
  }

  ul {
    font-size: 16px;
    line-height: 1.6;

    li {
      position: relative;

      padding-left: 30px;

      &:not(:last-child) {
        margin-bottom: 16.5px;
      }

      @mixin media 769 {
        br {
          display: none;
        }
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0.25em;
        /* transform: translateY(-50%); */

        font-size: 18px;

        display: block;
        width: 16px;
        height: 16px;

        background: url("../images/li-point.svg");
        background-size: contain;
        background-repeat: no-repeat;
      }

      * {
        line-height: 1.6;
      }
    }
  }
}
