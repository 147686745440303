html {
  font-size: 16px;

  @mixin media 1450 {
    font-size: 14px;
  }

  @mixin media 1025 {
    font-size: 12px;
  }

  @mixin media 551 {
    font-size: 10px;
  }
}

.fp-watermark {
  display: none;
}

.tab-only {
  @mixin min-media 1025 {
    display: none !important;
  }
}

body {
  position: relative;
  min-width: 320px;
  margin: auto;
  color: var(--black);

  overflow-x: hidden;

  &.disabled {
    overflow-y: scroll;
    position: fixed;

    width: 100%;
  }
}

.img-loader-wrap {
  display: none;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  width: 100%;
  height: ac(366px, 240px);

  @mixin media 1025 {
    height: 320px;
  }

  @mixin media 501 {
    height: 184px;
  }
}
.img-loader {
  width: 48px;
  height: 48px;
  margin: 0 auto;
  border: 5px solid var(--white);
  border-bottom-color: var(--magenta);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

.typed-cursor {
  position: absolute;
}

.swiper-wrapper {
  /* left: 0 !important; */
}

#site {
  position: relative;
  /* overflow: hidden; */
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.social-link {
  position: relative;

  i {
    position: relative;
    z-index: 1;
    transition: setTransition(color, background);
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    border-radius: 50%;

    background: var(--magenta-gr);

    opacity: 0;
    transform: scale(0.8);

    transition: setTransition(400ms, opacity, transform);
  }

  &:hover {
    color: var(--white);

    &::before {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.snackbar-container {
  z-index: 99999 !important;
}

.popup__block {
  overflow-x: hidden;

  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);

  width: 90vw;
  max-width: 1300px;
  height: fit-content;
  max-height: 90vh;

  padding: ac(56px, 12px);
  border-radius: 16px;

  background: var(--white);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08), 0px 0px 32px rgba(0, 0, 0, 0.14);

  &.apply-popup {
    max-width: 900px;
  }

  @mixin media 551 {
    width: 95vw;
    max-width: unset;
    /* height: 95vh;
    height: calc(var(--vh, 1vh) * 95); */
    min-height: 0;
    height: auto;
    max-height: min-content !important;
    padding-bottom: 40px;

    /* border-radius: 0; */
  }

  &.teammate-popup {
    display: block;

    max-height: 640px;
    height: 90vh;

    @mixin media 551 {
      padding: 24px 0 0;
    }

    .popup__btn-close {
      @mixin media 901 {
        display: none;
      }
    }
  }

  .popup__btn-close {
    top: -1px;
    right: -1px;
    overflow: hidden;

    width: ac(56px, 40px);
    height: ac(56px, 40px);

    border-radius: 0 0 0 15px;

    color: var(--white);
    background: var(--light-gray);

    font-size: ac(24px, 12px);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;

      background: var(--magenta-gr);

      transition: setTransition(opacity);
    }

    &:hover {
      &::before {
        opacity: 0;
      }

      .icon-close {
        color: var(--magenta);
      }
    }

    .icon-close {
      position: relative;
      z-index: 1;

      transition: setTransition(color);
    }
  }

  .popup__content,
  .popup__container {
    height: 100%;
  }
}

.sign-up__inputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .upload-input {
    grid-column: 1 / 3;
  }

  @mixin media 501 {
    grid-template-columns: 1fr;

    .upload-input {
      grid-column: unset;
    }
  }
}

.agreement {
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  input[type="checkbox"] {
    accent-color: var(--magenta);
  }

  .checkBox {
    display: flex;
    align-items: center;

    input {
      margin-right: 10px;
    }

    span {
      font-size: 14px;
    }
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }
}

.teammate-popup__content-wrap {
  display: flex;
  justify-content: space-between;

  height: 100%;

  @mixin media 901 {
    display: grid;
    grid-template-rows: auto minmax(200px, 1fr);

    /* flex-direction: column; */
    justify-content: unset;
  }
}

.teammate-popup__content-left {
  width: 36%;

  @mixin media 901 {
    width: 100%;
  }
}

.teammate-popup__photo-socials {
  @mixin media 901 {
    display: flex;
    justify-content: center;

    margin-bottom: 16px;
  }
}

.teammate-popup__logo-wrap {
  display: flex;
  align-items: center;

  margin-bottom: 26px;

  @mixin media 901 {
    justify-content: center;
  }

  &::before,
  &::after {
    content: "";

    flex-grow: 1;

    height: 1px;
    background: var(--light-gray);

    @mixin media 901 {
      flex-grow: unset;

      width: 40px;
    }
  }
}

.teammate-popup__logo {
  max-width: 205px;

  margin: 16px;

  @mixin media 901 {
    max-width: 122px;
  }
}

.teammate-popup__photo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;

  width: ac(296px, 200px);
  height: ac(296px, 200px);

  margin: 0 auto 24px;
  padding: ac(16px, 8px);
  border-radius: 9999px;

  background: var(--light-blue);

  @mixin media 901 {
    width: 152px;
    height: 152px;

    margin: 0;
  }
}

.teammate-popup__photo {
  width: 100%;
  height: 100%;
}

.teammate-popup__socials-list {
  display: flex;
  align-items: center;
  justify-content: center;

  @mixin media 901 {
    flex-direction: column;
  }
}

.teammate-popup__socials-item {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 45px;
  height: 45px;

  border-radius: 999px;
  border: 1px solid var(--magenta);

  &:not(:last-child) {
    margin-right: 16px;
  }

  @mixin media 901 {
    width: 38px;
    height: 38px;

    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }

  i {
    font-size: 19px;

    background: var(--magenta-gr);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &.icon-mail {
      font-size: 30px;
    }

    @mixin media 901 {
      font-size: 15px;

      &.icon-mail {
        font-size: 24px;
      }
    }
  }

  &:hover {
    i {
      background: var(--white);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.teammate-popup__content-right {
  width: 60%;

  padding: 32px 56px 48px;
  border-radius: 16px;

  background: var(--light-blue);

  @mixin media 901 {
    width: 100%;
    height: 100%;

    padding: 16px 48px 82px 24px;
    border-radius: 16px;
    border: 1px solid var(--light-gray);
  }
}

.teammate-popup__right-content-wrap {
  max-height: 528px;
  height: 100%;

  padding-right: 48px;
  margin-right: -16px;

  @mixin media 901 {
    max-height: 488px;

    padding-right: 32px;
    margin-right: -16px;
  }

  .simplebar-vertical {
    width: 16px;

    border-radius: 999px;

    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.18);

    background: var(--white);
  }

  .simplebar-scrollbar {
    &::before {
      background: var(--light-gray) !important;
    }
  }

  .simplebar-track.simplebar-vertical {
    /* transform: translateX(calc(100% + 15px)); */
    transform: none !important;
  }
}

.teammate-popup__name {
  margin-bottom: 8px;

  font-size: ac(48px, 26px);
  font-weight: 700;
  line-height: 1.34;
}

.teammate-popup__position {
  margin-bottom: 24px;

  font-size: ac(20px, 16px);

  background: var(--magenta-gr);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.teammate-popup__biography {
  margin-bottom: ac(24px, 16px);
  padding-top: ac(24px, 16px);
  padding-bottom: ac(24px, 16px);
  border-top: 1px solid var(--light-gray);
  border-bottom: 1px solid var(--light-gray);
}

.teammate-popup__biography-title {
  margin-bottom: ac(16px, 8px);

  font-size: ac(24px, 16px);
  font-weight: 700;
}

.teammate-popup__biography-text {
  max-width: 503px;

  @mixin media 769 {
    &.content-element * {
      font-size: 14px;
    }
  }
}

.teammate-popup__specialisms-title {
  margin-bottom: ac(16px, 8px);

  font-size: ac(24px, 16px);
  font-weight: 700;
}

.teammate-popup__specialisms-list {
  display: flex;
  flex-wrap: wrap;

  max-width: 100%;

  margin-right: -16px;
  margin-bottom: -16px;

  @mixin media 901 {
    margin-right: -8px;
    margin-bottom: -8px;
  }
}

.teammate-popup__specialisms-item {
  margin-right: 16px;
  margin-bottom: 16px;

  box-shadow: none;

  background: var(--white);

  @mixin media 901 {
    margin-right: 8px;
    margin-bottom: 8px;

    min-width: unset;

    height: 32px;

    font-size: 12px;

    .btn__text {
      padding: 0 16px !important;
    }

    .btn__arrow {
      width: 32px !important;
    }
  }
}

.teammate-popup__mobile-close {
  /* position: fixed;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%); */

  display: none;

  width: calc(100% - 16px);
  max-width: 450px;

  margin-top: 15px;

  background: var(--white);

  @mixin media 901 {
    display: block;
  }
}

.atropos-shadow {
  background: rgba(0, 0, 0, 0.15);
}

.first-section__main-img-wrap {
  .atropos-shadow {
    border-radius: 500px;
  }
}

.main {
  flex-grow: 1;
}

%default-transition {
  transition: ease-in-out 0.25s;
}

.cont {
  margin: 0 auto;
  max-width: 1300px;
  width: perc(1300);

  @mixin tab-md {
    /* width: 89%; */
    width: perc(328, 360);
  }
}

.section {
  position: relative;
  overflow-x: hidden;
}
.section__title,
.inner-first-section .first-section__title {
  .highlight {
    display: inline-block;
    transform: scale(0.5);
    opacity: 0;

    transition: setTransition(400ms, opacity, transform);
    transition-delay: 500ms;
  }

  &.aos-animate {
    .highlight {
      transform: scale(1);
      opacity: 1;
    }
  }
}

.section__title {
  margin-bottom: ac(64px, 22px);

  color: var(--black);
  font-size: ac(48px, 34px);
  font-weight: 700;
  line-height: 1.2;
}

#bold-credits {
  display: block;

  width: 150px;
  transition: ease-in-out 0.25s;
  margin: 0 auto;

  &::before,
  &::after {
    display: none;
  }

  &:hover {
    transform: scale(1.1);
  }
}

.simplebar-scrollbar {
  &::before {
    opacity: 1 !important;
    background: var(--magenta-gr) !important;
  }
}

[data-simplebar-outside] {
  .simplebar-track.simplebar-vertical {
    transform: translateX(100%);
  }
}

.sticky:before,
.sticky:after {
  content: "";
  display: table;
}

.highlight {
  background: var(--magenta-gr);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.first-section {
  overflow: hidden;

  padding-top: calc(var(--header-h) + ac(30.5px, 10px));
  padding-bottom: 56px;

  /* height: 100vh; */
  /* min-height: ac(800px, 500px); */
  height: ac(800px, 500px);

  background: var(--light-blue);

  @mixin media 769 {
    height: 100vh;
    min-height: 725px;
    max-height: 800px;
  }

  .cont {
    display: flex;
    align-items: center;

    height: 100%;

    @mixin media 769 {
      padding-top: 21px;
      flex-direction: column-reverse;

      height: calc(100%; - var(--header-h));
    }
  }

  &.inner-first-section {
    padding-top: var(--header-h);
    padding-bottom: 0;
    height: 808px;

    @mixin media 769 {
      padding-bottom: 56px;
      height: auto !important;
      min-height: unset;
    }

    .cont {
      justify-content: space-between;

      @mixin media 769 {
        padding-top: 80px;
      }
    }

    .first-section__text-wrap {
      position: relative;
      top: 20px;
      z-index: 1;

      @mixin media 769 {
        top: 0;

        padding: 0 5px 0 14px;
      }
    }

    .first-section__title {
      height: auto;
    }

    .first-section__descr {
      max-width: 460px;
    }

    .first-section__main-img-wrap {
      position: relative;

      display: flex;
      align-items: end;

      height: 100%;

      @mixin media 769 {
        clip-path: inset(-100% -100% 0px -100%);

        margin-bottom: 41px;
      }
    }

    .first-section__main-img-bg {
      position: absolute;
      bottom: ac(-150px, -46px);
      left: 50%;
      transform: translateX(-44%);

      width: ac(826px, 328px);
      max-width: none;
      height: ac(826px, 328px);

      @mixin media 769 {
        transform: translateX(-50%);

        /* bottom: 0; */
      }
    }

    .first-section__img {
      position: relative;
      left: 25px;
      z-index: 1;

      object-position: bottom;

      width: ac(540px, 231px);
      height: ac(540px, 231px);

      @mixin media 769 {
        left: -5px;
        margin-bottom: 0;
      }
    }

    &.logo-multicircle {
      padding-top: var(--header-h);

      .cont {
        @mixin media 769 {
          padding-top: 0;
        }
      }

      .first-section__text-wrap {
        @mixin media 769 {
          padding: 0 7px 0 9px;
        }
      }

      .first-section__descr {
        @mixin media 769 {
          margin-bottom: 23px;
        }
      }

      .first-section__main-img-wrap {
        align-items: center;

        padding: 0 30px;

        @mixin media 769 {
          position: relative;
          left: 10px;

          height: 314px;

          margin-left: auto;
          margin-bottom: 40px;

          clip-path: unset;
        }
      }

      .first-section__img {
        width: 105px;
        height: 105px;

        left: 0;

        @mixin media 769 {
          width: 52px;
          height: 52px;
        }
      }

      .first-section__main-img-bg {
        top: 50%;
        bottom: unset;
        transform: translate(-50%, -50%);
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-name: pulse;

        @keyframes pulse {
          0% {
            transform: translate(-50%, -50%) scale(0.95);
          }

          50% {
            transform: translate(-50%, -50%) scale(1);
          }

          100% {
            transform: translate(-50%, -50%) scale(0.95);
          }
        }

        width: ac(1072px, 650px);
        height: ac(1072px, 650px);

        mask-image: linear-gradient(
          180deg,
          rgba(217, 217, 217, 0) 16.98%,
          #d9d9d9 50%,
          rgba(217, 217, 217, 0) 83.12%
        );

        @mixin media 769 {
          width: 530px;
          height: 530px;
        }
      }
    }

    &.center-multicircle {
      .cont {
        @mixin media 769 {
          padding-top: 20px;
        }
      }

      .first-section__text-wrap {
        top: 5px;
      }

      .first-section__main-img-wrap {
        width: ac(620px, 310px);
        height: ac(620px, 310px);

        align-items: center;
        justify-content: center;

        @mixin media 769 {
          margin-bottom: 21px;
        }
      }

      .first-section__main-img-bg {
        top: 50%;
        bottom: unset;
        transform: translate(-50%, -50%);

        width: ac(620px, 310px);
        height: ac(620px, 310px);

        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-name: pulse;

        @keyframes pulse {
          0% {
            transform: translate(-50%, -50%) scale(0.95);
          }

          50% {
            transform: translate(-50%, -50%) scale(1);
          }

          100% {
            transform: translate(-50%, -50%) scale(0.95);
          }
        }
      }

      .first-section__img {
        left: 0;

        width: ac(430px, 193px);
        height: ac(430px, 193px);
        object-fit: cover;
        object-position: center;

        border-radius: 9999px;

        margin-bottom: 0;
      }
    }

    &.center-circle {
      .cont {
        padding-top: 30px;
      }

      .first-section__text-wrap {
        top: -13px;

        @mixin media 769 {
          top: 0;
        }
      }

      .first-section__main-img-wrap {
        top: -13px;
        z-index: 1;

        width: ac(584px, 280px);
        height: ac(584px, 280px);
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        clip-path: unset;

        margin-right: ac(65px, 0px);

        @mixin media 769 {
          top: 0;
        }
      }

      .first-section__img {
        left: 0;

        width: 100%;
        height: 100%;

        object-fit: contain;
        object-position: center;

        &.candidate-details-main-img {
          .candidate-details-main-img__img {
            position: absolute;
            z-index: -1;

            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 9999px;
          }
          .candidate-details-main-img__fg {
            mix-blend-mode: hard-light;
          }
        }
      }
    }

    &.cut-circle {
      height: ac(740px, 500px);

      .cont {
        @mixin media 769 {
          padding-top: 0;
        }
      }

      &:not(&.insights) {
        .first-section__text-wrap {
          top: 0;
        }
      }

      .first-section__main-img-wrap {
        position: relative;
        width: 75%;
        max-width: 620px;
        flex-grow: 1;

        @mixin media 769 {
          width: 100%;
          height: 312px;

          margin-bottom: 40px;

          clip-path: inset(-100% -100% 0px -100%);
        }
      }

      .first-section__img {
        position: absolute;
        width: ac(852px, 700px);
        max-width: none;
        height: ac(852px, 700px);
        left: 0;
        top: 50%;
        object-fit: cover;
        object-position: center;
        transform: translateY(-50%);
        border-radius: 50%;

        @mixin media 769 {
          left: unset;
          right: 0;

          width: ac(700px, 444px, 375, 768);
          height: ac(700px, 444px, 375, 768);

          transform: translate(30%, -50%);
        }

        &.insights-details-main-img {
          .insights-details-main-img__img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            border-radius: 99999px;
            width: 61.3%;
            height: 61.3%;
            object-fit: cover;
          }
        }
      }
    }
  }
}

.first-section__text-wrap {
  position: relative;
  top: -9px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  max-width: 650px;
  width: 69%;

  margin-right: 29px;

  @mixin media 769 {
    top: unset;

    width: 100%;
    max-width: 100%;

    margin-right: 0;
    padding: 0 14px;
  }
}

.first-section__title {
  height: ac(150px, 81px);

  margin-bottom: ac(40px, 26px);

  opacity: 0.9;

  font-size: ac(72px, 34px);
  line-height: 1.19;

  @mixin media 360 {
    height: 100px;
  }
}

.first-section__descr {
  max-width: 360px;

  margin-bottom: ac(32px, 16px);

  font-size: ac(20px, 16px);
  line-height: 1.6;
}

.first-section__btns-wrap {
  display: flex;

  @mixin media 401 {
    flex-direction: column;
  }

  .btn {
    width: 200px;
    height: 40px;

    &:not(&.btn--arrow) {
      padding: 8px 10px;
      width: 224px;

      @mixin media 551 {
        width: 100%;
      }
    }

    &:not(:last-child) {
      margin-right: 16px;
      width: 160px;
    }

    @mixin media 551 {
      width: 100%;
    }

    @mixin media 401 {
      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }
}

.first-section__date-wrap {
  display: flex;
  align-items: center;

  @mixin media 769 {
    margin-top: 8px;
  }
}

.first-section__date-logo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 56px;
  height: 56px;

  margin-right: 16px;
  border-radius: 50%;

  background: var(--white);
}

.first-section__date-logo {
  width: 32px;
  height: 32px;
}

.first-section__date {
  background: var(--magenta-gr);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: 20px;
}

.first-section__img {
  width: ac(621px, 280px);
  height: ac(621px, 280px);
  object-fit: contain;

  @mixin media 769 {
    margin-bottom: 46px;
  }
}

.accordion-section {
  overflow: hidden;

  padding: ac(80px, 60px) 0;
}

.accordion {
  position: relative;

  align-items: stretch;
  display: flex;
  width: 100%;
  background: var(--white);

  @mixin tab-sm {
    flex-direction: column;
  }
}

.accordion__item {
  position: relative;
  overflow: hidden;
  z-index: 1;

  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;

  height: ac(508px, 458px);

  padding: 30px ac(77px, 30px);
  border: 1px solid var(--light-gray);
  border-radius: 16px;

  transition: setTransition(1000ms, flex, box-shadow);

  &:not(:last-child) {
    margin-right: 20px;
  }

  &:not(.active) {
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
  }

  &.active {
    flex-grow: 1;

    &:not(:first-child) {
      /* margin-left: 20px; */
    }

    &:not(:last-child) {
      margin-right: 35px;
    }

    .accordion__item-closed-bar {
      opacity: 0;
      pointer-events: none;
      transform: translateX(-50px);
    }

    .accordion__bg {
      opacity: 1;
    }

    .accordion__info-block {
      pointer-events: unset;
      opacity: 1;
      transform: unset;
      transition-delay: 0.75s;
    }
  }

  @mixin tab-sm {
    width: 100%;
    flex: none;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    height: auto;
    max-height: 80px;

    padding: 35px 25px 30px 24px;

    background: var(--white);

    transition: setTransition(800ms, box-shadow, max-height);

    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 8px;
    }

    &.active {
      max-height: 1000px;

      &:not(:first-child) {
        margin-left: 0;
        margin-top: 16px;
      }

      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 24px;
      }
      .accordion__item-closed-bar {
        transform: translate(-50%, -100px);
        opacity: 0;
      }

      .accordion__info-block {
        height: auto;
      }
    }
  }
}

.accordion__item-closed-bar {
  position: absolute;
  top: 0;
  left: 2px;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 100%;

  padding: 37.5px 0 48px;

  opacity: 1;

  transition: transform 0.5s ease, opacity 0.5s ease;
  /* width: ac(400px, 280px); */

  &:hover {
    .accordion__plus-closed {
      color: var(--magenta);
    }
    .accordion__title-closed {
      color: var(--magenta);
    }
    .accordion__num-closed {
      color: var(--magenta);
    }
  }

  @mixin tab-sm {
    /* position: static; */
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 48px);
    left: 50%;
    flex-direction: row;

    /* width: 100%; */
    height: auto;

    padding: 0;

    /* transform: translateY(0); */
  }
}

.accordion__num-closed {
  margin-bottom: ac(24px, 12px);

  color: var(--gray);
  font-size: ac(28px, 16px);

  transition: setTransition(color);

  @mixin tab-sm {
    margin-bottom: 0;
    margin-right: 16px;
    border-right: 2px solid var(--light-gray);
    padding: 10.5px 9px 10.5px 0;

    font-size: 16px;
  }
}

.accordion__title-closed {
  /* @mixin max-line-length-one; */

  font-size: ac(36px, 22px);
  font-weight: 700;
  line-height: 1.42;
  writing-mode: vertical-rl;

  transition: setTransition(color);

  @mixin tab-sm {
    margin-bottom: 0;
    margin-right: 16px;

    writing-mode: unset;
  }
}

.accordion__plus-closed {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  width: ac(48px, 36px);
  height: ac(48px, 36px);

  margin-top: auto;

  border: 2px solid var(--magenta);

  border-radius: 4px;

  font-size: ac(24px, 18px);

  transition: setTransition(color);

  @mixin tab-sm {
    width: 32px;
    height: 32px;

    margin-top: 0;
    margin-left: auto;

    font-size: 16px;
  }
}

.accordion__info-block {
  position: absolute;
  top: 31px;
  left: ac(56px, 30px);
  pointer-events: none;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 80%;
  height: calc(100% - 84.5px);

  opacity: 0;
  transform: translateX(25%);
  transition: transform 0.7s ease, opacity 0.6s ease;

  @mixin tab-sm {
    position: relative;
    top: 0;
    left: 0;

    padding-left: 13px;
    /* margin-top: -30px; */

    width: 100%;

    transform: translateY(25%);
    transition: opacity 0.6s ease, transform 0.6s ease, padding 0.6s ease,
      height 0.6s ease, max-height 0.6s ease;
    transition-delay: 0s;
  }
}

.accordion__info-block-top {
  @mixin tab-sm {
    margin-bottom: 30px;
  }
}

.accordion__info-block-minus {
  position: absolute;
  top: 0;
  right: 0;

  display: none;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border: 1px solid var(--white);
  border-radius: 4px;

  transition: setTransition(background);

  span {
    display: block;
    width: 16px;
    height: 1.5px;
    background: var(--white);

    transition: setTransition(background);
  }

  &:hover {
    background: var(--white);

    span {
      background: var(--magenta);
    }
  }

  @mixin media 769 {
    display: flex;
  }
}

.accordion__info-block-bottom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: auto;
}

.accordion__num {
  width: fit-content;

  margin-bottom: 10px;
  padding: 5.5px;
  border-bottom: 1px solid var(--white);

  color: var(--white);
  font-size: ac(28px, 16px);
  line-height: 1.4;

  @mixin tab-sm {
    margin-bottom: 27px;
  }
}

.accordion__title {
  color: var(--white);
  font-size: ac(38px, 26px);
  font-weight: 700;
  line-height: 1.2;
}

.accordion__info-text {
  @mixin max-line-length 7;

  max-width: 520px;

  margin-bottom: ac(26px, 24px);

  color: var(--white);
  font-size: 16px;
  line-height: 1.6;

  * {
    color: var(--white);
    font-size: 16px;
    line-height: 1.6;
  }
}

.accordion__bg {
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  transition: opacity 0.25s ease;
  z-index: -1;

  &::before {
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    background: linear-gradient(
      to right,
      #b919aa 5%,
      #2f4596 50%,
      #2afacf 125%
    );

    /* mix-blend-mode: soft-light; */
  }
  &::after {
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    z-index: 1;
    background: rgba(0, 0, 0, 0.2);

    /* mix-blend-mode: soft-light; */
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    mix-blend-mode: soft-light;
  }
}

.accordion__decor {
  position: absolute;
  bottom: -62px;
  left: 51%;
  transform: translateX(-50%);
  z-index: -1;

  width: ac(483px, 192px);

  opacity: 0.2;

  @mixin tab-sm {
    bottom: -34px;
    left: unset;
    right: 15px;
    transform: none;

    opacity: 1;
  }
}

.community {
  .cont {
    display: flex;

    @mixin media 951 {
      flex-direction: column;
    }
  }
}

.community__img-block {
  position: relative;
  overflow: hidden;

  width: 50.5%;

  margin-right: ac(40px, 20px);
  border-radius: 16px;

  @mixin media 951 {
    width: 100%;
    max-width: 600px;
    height: 328px;

    margin: 0 auto ac(20px, 16px);
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    background: rgba(22, 28, 44, 0.3);
  }

  .community__img,
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.community__info-block {
  width: 49.5%;

  @mixin media 951 {
    width: 100%;
  }
}

.community__main,
.community__list-wrap {
  padding: ac(38px, 24px) ac(48px, 24px) ac(50px, 24px);
  border: 1px solid var(--light-gray);
  border-radius: 16px;
}

.community__main {
  margin-bottom: ac(20px, 16px);
}

.community__list-wrap {
  padding-bottom: ac(73px, 24px);
  padding-right: ac(115px, 20px);
}

.community__title {
  max-width: 429px;

  margin-bottom: ac(24px, 18px);

  font-size: ac(36px, 26px);
  line-height: 1.4;

  @mixin media 551 {
    max-width: 232px;
    line-height: 1.34;
  }
}

.community__descr {
  margin-bottom: ac(24px, 18px);

  font-size: ac(18px, 16px);
  line-height: 1.6;

  @mixin media 551 {
    max-width: 200px;
  }
}

.community__btn {
  @mixin media 501 {
    width: 100%;
  }
}

.community__list-title {
  margin-bottom: ac(39px, 27px);

  font-size: 24px;
  font-weight: 700;
  line-height: 1.4;

  @mixin media 951 {
    font-size: 26px;
  }
}

.community__list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ac(36px, 20px) 20px;

  @mixin media 551 {
    grid-template-columns: 1fr;
    gap: 8px;
  }
}

.community__list-item {
}

.community__list-link {
  display: flex;
  align-items: center;

  border-radius: 4px;

  cursor: pointer;
  transition: setTransition(background, color);

  &:hover {
    .community__list-item-icon {
      color: var(--white);
      background-color: var(--magenta);

      object {
        filter: invert(1);
      }
    }
  }

  @mixin media 551 {
    padding-left: 19px;

    &:not(:last-child) {
      padding-bottom: 9px;
      border-bottom: 1px solid var(--light-gray);
    }
  }
}

.community__list-item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  width: 44px;
  height: 44px;

  object {
    width: ac(23px, 18px);
    height: ac(23px, 18px);

    pointer-events: none;

    transition: setTransition(filter);
  }

  margin-right: 18px;
  border: 2px solid var(--magenta);
  border-radius: 4px;

  font-size: ac(23px, 18px);

  transition: setTransition(color, background);

  @mixin media 951 {
    width: 32px;
    height: 32px;

    border-width: 1px;
  }
}

.community__list-item-text {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}

.contact-us {
  margin: ac(80px, 56px) 0 30px;

  .cont {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: ac(68px, 48px) ac(40px, 24px) ac(74px, 48px);
    border-radius: 16px;

    background: var(--light-blue);
  }
}

.contact-us__title {
  display: flex;
  align-items: center;

  margin-bottom: 59px;

  text-align: center;

  @mixin media 769 {
    flex-direction: column;

    margin-bottom: 68px;
  }
}

.contact-us__title-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  width: ac(70px, 56px);
  height: ac(70px, 56px);

  margin-right: 16px;
  border: 1px solid var(--light-gray);
  border-radius: 50%;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);

  background: var(--white);

  @mixin media 769 {
    margin-bottom: 12px;
    margin-right: 0;
  }
}

.contact-us__title-logo-img {
  width: ac(36px, 29px);
  height: ac(36px, 29px);
}

.contact-us__title-text {
  font-size: ac(48px, 34px);

  @mixin tab-sm {
    line-height: 1.2;
  }
}

.contact-us__form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  margin-top: -20px;

  @mixin media 769 {
    flex-direction: column;
  }

  @mixin media 551 {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}

.contact-us__form-title {
  margin-top: 20px;
  margin-right: ac(40px, 20px);

  font-size: 24px;
  font-weight: 700;

  @mixin media 769 {
    margin-right: 0;
  }

  @mixin media 551 {
    width: 100%;

    font-size: 16px;
  }
}

.contact-us__form-swiper {
  overflow: hidden;

  max-width: 350px;

  margin-right: 24px;
  margin-top: 20px;

  @mixin media 769 {
    margin-right: 0;
  }

  @mixin media 551 {
    width: 100%;
    margin-top: 24px;
  }

  .swiper-slide {
    position: relative;

    &.error {
      .input,
      .textarea {
        padding-right: 70px;
      }

      &::before {
        content: "Required!";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        color: var(--magenta);
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}

.contact-us__form-swiper-nav {
  display: flex;

  margin-top: 20px;

  @mixin media 551 {
    justify-content: center;

    width: 100%;

    margin-top: 23px;
  }
}

.contact-us__form-swiper-btn {
  justify-content: normal;

  min-width: 171px;

  padding: 0;

  @mixin media 551 {
    width: 100%;
    min-width: 120px;
    max-width: 171px;
  }

  &::after {
    -webkit-mask: none;

    background: var(--white);

    opacity: 0;

    transition: setTransition(opacity);
  }

  &.swiper-button-disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &:not(.swiper-button-disabled):hover {
    &::after {
      opacity: 1;
    }

    .contact-us__form-swiper-btn-icon {
      border-color: var(--light-gray);
      color: var(--magenta);
    }
  }

  &.swiper-button-disabled:hover {
    color: var(--white);

    &::before {
      opacity: 1;
    }
  }

  &.prev {
    margin-right: 8px;

    .contact-us__form-swiper-btn-icon {
      border-left: none;
      border-right: 1px solid var(--light-gray);
    }

    &::after {
      background: var(--magenta-gr);
    }

    &:not(.swiper-button-disabled):hover {
      .contact-us__form-swiper-btn-icon {
        border-color: var(--white);
        color: var(--white);
      }
    }

    &.swiper-button-disabled:hover {
      color: var(--black);
    }

    .contact-us__form-swiper-btn-icon {
      color: var(--magenta);
    }
  }
}

.contact-us__form-swiper-btn-icon {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-left: 1px solid var(--white);

  transition: setTransition(color, border);
}

.contact-us__form-swiper-btn-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.partners {
  overflow: hidden;

  margin: ac(72px, 17px) 0 ac(92px, 59px);
  padding: 30px 0;

  @mixin media 769 {
    /* overflow: visible; */
  }

  .cont {
    display: flex;
    align-items: center;

    @mixin media 769 {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.partners__text-wrap {
  width: 100%;
  max-width: 388px;

  margin-right: ac(64px, 20px);

  @mixin media 769 {
    max-width: unset;

    margin-right: 0;
    margin-bottom: 16px;
  }
}

.partners__title {
  margin-bottom: ac(20px, 16px);

  font-size: ac(48px, 34px);
  line-height: 1.4;

  opacity: 0.9;
}

.partners__descr {
  margin-bottom: ac(30px, 18px);

  font-size: ac(18px, 16px);
  line-height: 1.6;

  @mixin media 769 {
    br {
      display: none;
    }
  }
}

.partners__swiper-nav,
.swiper-nav {
  display: flex;
}

.btn.partners__swiper-btn,
.btn.swiper-nav__btn {
  display: flex;
  justify-content: center;
  align-items: center;

  width: ac(56px, 40px);
  min-width: auto;
  height: ac(56px, 40px);

  padding: 0;
  border-radius: 0px 4px 4px 0px;

  /* background: var(--magenta-gr); */
  color: var(--white);
  box-shadow: none;

  transition: setTransition(opacity);

  &:not(&.swiper-button-disabled):hover {
    /* i {
      color: var(--gray);
    } */
    opacity: 0.75;
  }

  &::after {
    border-radius: 0px 4px 4px 0px;
  }

  i {
    font-size: ac(21px, 16px);
    color: var(--white);
  }

  &.swiper-button-disabled {
    cursor: not-allowed;

    opacity: 0.5;
  }

  &.prev {
    i {
      color: var(--gray);
    }

    &::after {
      border-radius: 4px 0px 0px 4px;
      padding-right: 0;
    }

    &::before {
      border-radius: 4px 0px 0px 4px;
      padding-right: 0;

      opacity: 0;
    }

    &:not(&.swiper-button-disabled):hover {
      /* &::before {
        opacity: 1;
      }

      i {
        color: var(--white);
      } */
      opacity: 0.7;
    }
  }

  &.next {
    &::after {
      padding-left: 0;
      border-radius: 0px 4px 4px 0px;
    }
    &::before {
      border-radius: 0px 4px 4px 0px;
      padding-left: 0;
    }

    &:not(&.swiper-button-disabled):hover {
      &::before {
        opacity: 1;
      }
    }
  }
}

.partners__swiper {
  width: 204px;
  height: 520px;

  user-select: none;
  /* clip-path: inset(-30px -640px -30px -7px); */

  @mixin media 769 {
    /* overflow: hidden; */

    width: 100%;
    height: 376px;
  }

  .swiper-slide {
    display: flex;
    flex-direction: column;

    /* width: 204px; */
    overflow: visible !important;

    &.swiper-slide-active {
      .partners__card {
        &:first-child:not(&.unhovered) {
          box-shadow: 0px 0px 26px 8px rgba(0, 0, 0, 0.14);

          &::before {
            opacity: 1;
          }

          .partners__card-logo {
            filter: grayscale(1) opacity(1) brightness(0) invert(1);
          }
        }
      }
    }

    /* @mixin media 769 {
      margin-top: 0;
    } */

    &:nth-child(6n + 1) {
      justify-content: flex-end;
    }
    &:nth-child(6n + 2) {
      justify-content: flex-start;
    }
    &:nth-child(6n + 3) {
      justify-content: center;
    }
    &:nth-child(6n + 4) {
      justify-content: flex-end;
    }
    &:nth-child(6n + 5) {
      justify-content: center;
    }
    &:nth-child(6n + 6) {
      justify-content: flex-start;
    }
    &:nth-child(6n + 7) {
      justify-content: flex-end;
    }
  }
}

.partners__card {
  position: relative;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 204px;
  height: 204px;

  border: 1px solid var(--light-gray);
  border-radius: 8px;

  background: var(--white);

  @mixin media 769 {
    /* width: 156px; */
    width: 100%;
    height: 156px;
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.14);

  transition: box-shadow 0.6s, opacity 0.6s, transform 0.6s !important;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    display: block;

    background: linear-gradient(310.83deg, #2afacf 16.95%, #2f4596 109.17%);

    opacity: 0;

    transition: setTransition(600ms, opacity);
  }

  @media (hover: hover) {
    &:hover {
      /* box-shadow: 0px 0px 26px 8px rgba(0, 0, 0, 0.14); */

      &::before {
        opacity: 1;
      }

      .partners__card-logo {
        filter: grayscale(1) opacity(1) brightness(0) invert(1);
      }
    }
  }
}

.partners__card-logo {
  position: relative;
  z-index: 1;

  max-width: 105px;
  max-height: 52px;
  width: 100%;
  height: 100%;
  object-fit: contain;

  filter: grayscale(1) opacity(0.7);

  transition: setTransition(600ms, filter);
}

.blueprint {
  padding-top: ac(40px, 0px);
  padding-bottom: ac(80px, 40px);

  .cont {
    position: relative;
    overflow: hidden;

    display: flex;

    max-width: 1060px;
    /* min-height: ac(638px, 420px, 1024); */

    padding-top: ac(64px, 34px);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      width: 90%;
      height: 1px;

      background: var(--light-gray);
    }

    @mixin media 769 {
      flex-direction: column;
    }
  }

  &.blueprint--revert {
    padding-top: ac(35px, 0px);
    padding-bottom: ac(62px, 40px);

    .cont {
      flex-direction: row-reverse;

      @mixin media 769 {
        flex-direction: column-reverse;
      }
    }

    .blueprint__left-content {
      margin-right: 0;

      @mixin media 769 {
        margin-bottom: 0;
      }
    }

    .blueprint__right-content {
      width: 45%;

      margin-right: ac(38px, 20px);

      @mixin media 769 {
        width: 100%;

        margin-right: 0;
        margin-bottom: 17px;
      }
    }
  }
}

.blueprint__left-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  width: 48%;
  /* min-height: 640px; */

  margin-right: ac(40px, 20px);

  @mixin media 769 {
    width: 100%;
    min-height: unset;

    margin-right: 0;
    margin-bottom: 18px;
  }
}

.blueprint__right-content {
  width: 40%;
  flex-grow: 1;

  @mixin media 769 {
    width: 100%;
    /* margin-bottom: 20px; */
  }
}

.blueprint__text-block {
  padding: ac(60px, 34px) ac(26px, 19px) ac(53px, 32px) ac(40px, 27px);

  border: 1px solid var(--light-gray);
  border-radius: 16px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);

  &:not(:last-child) {
    margin-bottom: ac(20px, 15px);
  }

  &:last-child {
    flex-grow: 1;
  }
}

.blueprint__title {
  /* max-width: 268px; */

  margin-bottom: ac(24px, 17px);

  font-size: ac(36px, 26px);
  line-height: 1.34;

  @mixin media 769 {
    width: 75%;
  }
}

.blueprint__descr {
  /* max-width: 388px; */

  margin-bottom: ac(20px, 15px);

  font-size: ac(20px, 16px);
  line-height: 1.6;

  * {
    font-family: var(--font-main) !important;
    font-size: ac(20px, 16px) !important;
    line-height: 1.6 !important;
  }
}

.blueprint__additional-text {
  max-height: 240px;

  color: var(--gray);

  font-size: 16px;
  line-height: 1.6;

  * {
    font-family: var(--font-main) !important;
    font-size: 16px !important;
    line-height: 1.6 !important;
  }

  @mixin media 769 {
    max-height: 270px;
  }
}

.blueprint__logo-wrap {
  position: relative;
  overflow: hidden;
  clip-path: inset(1px round 16px);

  display: flex;
  justify-content: center;

  height: 100%;

  padding: 20px;
  border-radius: 16px;

  @mixin media 769 {
    min-height: 328px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* z-index: 1; */

    background: linear-gradient(315deg, #2afacf 0%, #2f4596 132.99%);
  }
}

.blueprint__logo-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  mix-blend-mode: overlay;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blueprint__logo {
  position: relative;
  z-index: 2;

  width: 50%;
  max-width: 368px;
}

.growth-kit {
  background: var(--light-blue);

  padding-top: ac(64px, 40px);
  padding-bottom: ac(70px, 30px);

  .cont {
    overflow: hidden;
    padding-bottom: 10px;
  }
}

.growth-kit__title {
  margin-bottom: ac(64px, 21px);

  font-size: ac(48px, 34px);
  text-align: center;
}

.growth-kit__card-list {
  .swiper-wrapper {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));

    grid-gap: ac(16px, 10px);

    @mixin media 1025 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @mixin media 501 {
      display: flex;
      grid-gap: 0;

      .swiper-slide {
        width: 256px;
      }
    }
  }
}

.growth-kit__card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: ac(30px, 16px, 1024) ac(30px, 16px, 1024);
  border-radius: 16px;
  border: 1px solid var(--light-gray);

  background: var(--white);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.08);

  @mixin media 551 {
    padding: 32px 16px 40px;

    height: auto;
  }
}

.growth-kit__card-icon-wrap {
  position: relative;
  z-index: 1;

  padding: ac(16px, 8px);
  margin-bottom: ac(24px, 15px);

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 16px;
    padding: 2px;
    z-index: -1;
    background: var(--magenta-gr);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
}

.growth-kit__card-icon {
  width: ac(88px, 64px);
  height: ac(88px, 64px);
}

.growth-kit__card-title {
  margin-bottom: ac(24px, 15px);

  font-size: ac(24px, 22px);
  font-weight: 700;
  text-align: center;
}

.growth-kit__card-text {
  margin-bottom: 24px;
  width: 100%;

  font-size: 14px;
  line-height: 1.6;
  text-align: center;

  padding-right: 23px;
  margin-right: -23px;
  max-height: 114px;

  * {
    font-size: 14px;
    line-height: 1.6;
    text-align: center;
  }

  @mixin media 551 {
    margin-bottom: 34px;

    line-height: 1.4;

    * {
      line-height: 1.4;
    }
  }
}

.growth-kit__card-btn {
  margin-top: auto;
  padding: 8px 32px;
}

.part-of-community {
  overflow: hidden;

  padding: ac(64px, 30px) 0 ac(64px, 40px);

  background: var(--light-blue);

  .cont {
    position: relative;

    display: flex;
    align-items: center;

    padding: ac(64px, 30px);

    @mixin media 1025 {
      flex-direction: column;
      align-items: stretch;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;

      width: 100vw;

      border: 1px solid var(--light-gray);
      border-radius: 16px;

      background: var(--white);
    }
  }
}

.part-of-community__text-wrap {
  position: relative;
  z-index: 1;

  max-width: 360px;

  margin-right: ac(64px, 20px);

  @mixin media 1025 {
    max-width: 500px;

    margin-right: 0;
    margin-bottom: 24px;
  }
}

.part-of-community__title {
  margin-bottom: ac(24px, 7px);

  font-size: ac(36px, 26px);

  @mixin media 769 {
    line-height: 1.34;
  }
}

.part-of-community__descr {
  margin-bottom: ac(32px, 15px);

  font-size: ac(16px, 14px);
}

.community-swiper {
  max-width: 413px;

  @mixin media 651 {
    max-width: unset;
  }

  .swiper-slide {
    overflow: visible !important;
  }
}

.article-card {
  overflow: hidden;

  border-radius: 16px;
  border: 1px solid var(--light-gray);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.08);

  background: var(--white);

  transition: setTransition(border);

  &:hover {
    border-color: var(--magenta);
  }
}

.article-card__img {
  width: 100%;
  height: 232px;
  object-fit: cover;

  @mixin media 501 {
    height: 150px;
  }
}

.article-card__text-wrap {
  padding: ac(24px, 8px) ac(32px, 22px) ac(40px, 25px);
}

.article-card__title-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 104px;

  margin-bottom: ac(16px, 10px);

  @mixin media 901 {
    height: 75px;
  }
}

.article-card__title {
  @mixin max-line-length 3;

  font-size: ac(24px, 16px);
  font-weight: 700;
  line-height: 1.34;

  @mixin media 901 {
    /* @mixin max-line-length 2; */
  }
}

.article-card__descr-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 104px;

  margin-bottom: 16px;

  @mixin media 769 {
    height: 99px;
  }
}

.article-card__descr {
  @mixin max-line-length 4;

  color: var(--gray);

  font-size: ac(16px, 14px);
  line-height: 1.6;

  * {
    font-size: ac(16px, 14px);
    line-height: 1.6;
  }

  @mixin media 769 {
    @mixin max-line-length 5;

    line-height: 1.4;

    * {
      line-height: 1.4;
    }
  }
}

.btn.article-card__btn {
  .btn__text {
    padding: 0 42.5px;

    @mixin media 355 {
      padding: 0 28px;
    }
  }
}

.specialisms {
  overflow: hidden;

  padding: ac(80px, 57px) 0 ac(122px, 30px);
}

.specialisms__title {
  margin-bottom: ac(71px, 14px);
}

.specialisms__wrap {
  display: flex;
  height: 764px;

  @mixin media 1025 {
    height: auto;

    flex-direction: column;
  }
}

.specialisms__main-block {
  position: relative;

  width: 100%;

  margin-right: ac(40px, 15px);

  @mixin media 1025 {
    margin-right: 0;
  }
}

.specialisms__main-block-decor {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 28%);

  width: 74%;

  opacity: 0.5;
}

.specialisms__main-item {
  position: relative;
  overflow: hidden;
  z-index: 1;

  display: grid;
  grid-template-rows: auto minmax(0, 1fr);

  height: 100%;
  /* min-height: 764px; */

  border-radius: 16px;

  color: var(--white);
  background: var(--navy);

  transition: setTransition(400ms, opacity, transform);

  &.is-hidden {
    opacity: 0;
    transform: translateX(-50%);
  }

  @mixin media 1025 {
    min-height: unset;

    margin-bottom: 15px;
  }
}

.specialisms__main-img {
  width: 100%;
  height: ac(366px, 240px);
  object-fit: cover;

  @mixin media 1025 {
    height: 320px;
  }

  @mixin media 501 {
    height: 184px;
  }
}

.specialisms__main-text-wrap {
  /* max-height: 398px; */
  display: grid;
  grid-template-rows: auto minmax(0, 1fr) auto;

  padding: ac(30px, 22px) ac(64px, 20px) ac(62px, 30px) ac(48px, 28px);
}

.specialisms__main-title {
  margin-bottom: ac(20px, 15px);

  font-size: ac(36px, 22px);
  font-weight: 700;
}

.specialisms__main-text {
  /* max-height: 160px; */

  margin-bottom: ac(32px, 25px);

  font-size: ac(20px, 16px);
  line-height: 1.6;

  * {
    color: var(--white);

    font-size: ac(20px, 16px);
    line-height: 1.6;
  }
}

.specialisms__main-btn {
  width: 184px;
}

.specialisms__additional-blocks {
  position: relative;
  z-index: 1;

  display: grid;
  grid-auto-rows: 1fr;
  grid-gap: 8px;

  width: 100%;
  max-width: 610px;
  /* height: 764px; */

  padding: ac(16px, 0px) 0;

  @mixin media 1025 {
    grid-auto-rows: auto;

    max-width: unset;
  }
}

.specialisms__additional-item {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: center;

  padding: ac(24px, 22px) ac(32px, 16px);
  border: 1px solid var(--light-gray);
  border-radius: 8px;

  background: var(--white);

  text-align: left;

  transition: setTransition(color);

  &:hover {
    color: var(--magenta);

    .specialisms__additional-item-arrow {
      transform: translate(25%, 25%);
    }
  }

  transition: setTransition(400ms, opacity, transform);

  &.is-hidden {
    opacity: 0;
    transform: translateX(50%);
  }
}

.specialisms__additional-item-text-wrap {
  width: 84%;
}

.specialisms__additional-item-title {
  margin-bottom: ac(18px, 9px);

  font-size: ac(24px, 18px);
  font-weight: 700;
  line-height: 1.34;
}

.specialisms__additional-item-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.6;

  * {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.6;
  }
}

.specialisms__additional-item-arrow {
  align-self: flex-end;

  padding: ac(12px, 8px);

  font-size: 24px;

  background: var(--magenta-gr);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  transition: setTransition(400ms, transform);

  @mixin media 769 {
    position: relative;
    top: -5px;
  }
}

.storyteller {
  overflow: hidden;

  padding: ac(60px, 38px) 0 ac(80px, 38px);

  .cont {
    display: flex;
    align-items: center;

    @mixin media 769 {
      flex-direction: column;
    }
  }
}

.storyteller__title {
  flex-shrink: 0;

  margin-right: ac(53px, 25px);
  margin-bottom: 0;

  @mixin media 769 {
    margin-right: 0;

    margin-bottom: 24px;

    align-self: flex-start;
  }
}

.storyteller__right-wrap {
  position: relative;

  width: 100%;

  padding: ac(64px, 40px) 0;
}

.storyteller__text-wrap {
  padding: ac(48px, 24px) ac(72px, 24px) ac(48px, 27px) ac(64px, 27px);
  border-radius: 16px;

  box-shadow: 0 0 16px rgba(0, 0, 0, 0.12);
  background: var(--white);
}

.storyteller__text-title {
  margin-bottom: ac(16px, 7px);
  padding-bottom: ac(16px, 6px);
  border-bottom: 1px solid var(--light-gray);

  font-size: ac(36px, 26px);
  font-weight: 700;
  line-height: 1.34;
}

.storyteller__text {
  @mixin media 769 {
    max-height: 325px;
  }

  * {
    font-size: ac(16px, 14px);
    line-height: 1.65;

    @mixin media 769 {
      line-height: 1.4;
    }
  }
}

.storyteller__right-wrap-decor {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 26%;
  z-index: -1;
  overflow: hidden;

  width: 50vw;

  border-radius: 16px 0 0 16px;

  @mixin media 769 {
    left: 54%;

    width: 100vw;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    background: linear-gradient(315deg, #2afacf, #2f4596 132.99%);
  }
}

.storyteller__right-wrap-decor-img {
  position: relative;
  z-index: 1;

  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left center;
  /* mix-blend-mode: overlay; */

  opacity: 0.2;
}

.success-stories {
  padding: 64px 0;

  .cont {
    position: relative;

    padding-top: 80px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%);

      width: 100%;
      max-width: 1172px;
      height: 1px;

      background: var(--light-gray);
    }
  }

  @mixin media 769 {
    padding-top: 57px;
    padding-bottom: 30px;

    .cont {
      padding-top: 0;

      &::before {
        display: none;
      }
    }
  }

  &.no-line {
    padding: 43px 0;

    .cont {
      padding-top: 0;

      &::before {
        display: none;
      }
    }
  }
}

.success-stories__top-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: ac(68px, 40px);
}

.success-stories__title {
  margin-bottom: 0;

  margin-right: 20px;
}

.success-stories-swiper {
  .swiper-slide {
    width: fit-content;
    height: auto;
  }
}

.success-stories-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 313px;
  height: 100%;

  padding: ac(40px, 24px) ac(57px, 15px) ac(37px, 32px) ac(48px, 20px);
  border: 1px solid var(--light-gray);
  border-radius: 16px;

  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.25);

  @mixin media 360 {
    width: 251px;
  }
}

.success-stories-card__logo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 208px;
  height: 104px;

  margin-bottom: 12px;
  padding: 12px 8px;

  @mixin media 769 {
    height: 100px;

    margin-bottom: 0;
    padding: 17px 24px;
  }
}

.success-stories-card__logo {
  max-height: 100%;
}

.success-stories-card__text {
  width: 100%;
  max-height: 130px;

  margin-bottom: 12px;

  font-size: 14px;
  line-height: 1.2;
  text-align: center;
}

.success-stories-card__delimeter {
  display: block;

  width: 100%;
  height: 1px;

  margin-top: auto;
  margin-bottom: 32px;

  background: var(--light-gray);
}

/* MAP */
.map {
  overflow: hidden;

  padding-top: ac(63px, 12px);
  padding-bottom: 60px;

  .cont {
    @mixin media 769 {
      width: 100%;
    }
  }
}

.map__title {
  margin-bottom: ac(66px, 21px);

  @mixin media 769 {
    width: perc(328, 360);
    margin: 0 auto 21px;
  }
}

.map__wrap {
  width: 100%;
  max-height: ac(856px, 550px);
  height: auto;

  mask-image: linear-gradient(
    to bottom,
    rgba(217, 217, 217, 0) 0%,
    rgba(217, 217, 217, 0.3) 12.5%,
    #d9d9d9 51.87%,
    rgba(217, 217, 217, 0.3) 87.5%,
    rgba(217, 217, 217, 0) 100%
  );

  @mixin media 769 {
    height: 407px;
    flex-basis: auto;

    mask-image: none;

    opacity: 0.8;
  }
}

.map__locations-btn-list {
  @mixin media 769 {
    width: perc(328, 360);
    margin: 0 auto 24px;
  }
}

.map__locations-btn {
  position: relative;

  padding: ac(15px, 7px) ac(23px, 12px) ac(17px, 7px);

  color: var(--gray);

  font-size: ac(16px, 14px);
  font-weight: 500;

  &:not(:last-child) {
    margin-right: ac(16px, 8px);

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: calc(100% + ac(8px, 4px));
      transform: translate(-50%, -50%);

      width: 1px;
      height: ac(32px, 24px);

      background: var(--light-gray);
    }
  }

  transition: setTransition(color);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;

    opacity: 0;

    border-radius: 4px;

    background: var(--magenta-gr);

    transition: setTransition(opacity);
  }

  &:hover {
    color: var(--black);
  }

  &.active {
    color: var(--white);

    &::before {
      opacity: 1;
    }
  }

  @mixin media 769 {
    padding: 5.5px 12px;
  }
}

.map__container {
  position: relative;

  @mixin media 769 {
    margin-bottom: 24px;
    border-top: 1px solid var(--light-gray);
    border-bottom: 1px solid var(--light-gray);
  }
}

.map__offices-info {
  position: absolute;
  top: ac(32px, 16px);
  left: ac(32px, 16px);
  z-index: 1;

  max-width: 318px;

  border: 1px solid #e6e6e6;
  padding: ac(20px, 10px) ac(32px, 16px) ac(48px, 24px);

  box-shadow: 0px 0px 16px var(--light-gray);
  border-radius: 16px;

  background: var(--white);

  @mixin media 769 {
    display: none;
  }
}

.map__offices-title {
  margin-bottom: ac(16px, 10px);

  font-size: ac(24px, 20px);
  font-weight: 700;
}

.map__offices-offices-text {
  font-size: ac(16px, 14px);
  line-height: 1.6;
}

.map__chart-wrap {
  position: absolute;
  top: ac(32px, 16px);
  right: ac(32px, 16px);
  z-index: 1;
  overflow: hidden;
  transform: translateX(100%);
  opacity: 0;
  visibility: hidden;

  max-width: ac(336px, 290px, 1025);

  transition: setTransition(400ms, transform, opacity, visibility);

  &.active {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }

  @mixin media 1025 {
    &.active {
      transform: translateX(100%);
      opacity: 0;
      visibility: hidden;

      &.is-open {
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
      }
    }
  }

  @mixin media 769 {
    position: fixed;
    top: 0;
    /* top: 50%; */
    /* transform: translateY(-50%); */
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999;

    display: flex;
    align-items: center;

    width: 100%;
    max-width: unset;

    padding: 15px;

    background: rgba(0, 0, 0, 0.65);

    &.active {
      transform: translateX(0);
    }
  }
}

.map__chart-inner-wrap {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;
  max-height: calc(var(--vh, 1vh) * 90);

  border: 1px solid var(--light-gray);
  border-radius: 4px 4px 16px 16px;
  background: var(--white);
}

.map__chart-tab-list {
  display: flex;
  border-bottom: 1px solid var(--light-gray);
}

.map__chart-tab {
  position: relative;
  overflow: hidden;

  flex-grow: 1;

  padding: 8px 0;

  &:not(:last-child) {
    border-right: 1px solid var(--light-gray);
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    /* z-index: -1; */

    opacity: 0;

    background: var(--magenta-gr);

    transition: setTransition(opacity);
  }

  &.active {
    border-radius: 4px 4px 0px 0px;
    border-color: transparent;

    color: var(--white);

    &::before {
      opacity: 1;
    }
  }
}

.map__chart-tab-text {
  position: relative;
  width: 100%;

  font-size: ac(14px, 12px);
}

.map__chart-main {
  min-width: 319px;

  padding: ac(22px, 12px, 1024) ac(32px, 24px) ac(39px, 12px, 1024);

  @mixin media 1281 {
    min-width: unset;
  }

  @mixin media 769 {
    display: grid;
    grid-template-rows: auto auto minmax(0, 1fr) auto;

    overflow: auto;

    height: calc(100% - 32px);

    padding-bottom: 40px;
  }
}

.map__chart-close-btn {
  display: none;

  width: 100%;
  max-width: 350px;

  margin: 48px auto 0;

  @mixin media 1025 {
    display: block;
  }
}

.map__chart-main-text-wrap {
  margin-bottom: ac(24px, 12px, 1024);
}

.map__chart-main-title {
  margin-bottom: 8px;

  font-size: ac(24px, 20px, 1024);
  font-weight: 700;
  text-align: center;
}

.map__chart-main-text {
  font-size: 14px;
  line-height: 1.34;
  text-align: center;
}

.map__chart-canvas-wrap {
  display: flex;
  justify-content: center;

  margin: 0 auto 24px;

  @mixin media 1281 {
    margin-bottom: 40px;
  }

  @mixin media 769 {
    max-width: 100%;
  }
}

.map__chart {
  max-width: 200px;
  width: ac(200px, 90px);
  height: ac(200px, 90px);
  margin: 0 auto;

  @mixin media 1281 {
    max-width: 90px;
    max-height: 90px;
  }

  @mixin media 769 {
    margin-right: 16px;
    margin-left: 0;
  }
}

.map__chart-perc-wrap {
  display: none;

  width: 110px;
  height: 90px;

  @mixin media 1281 {
    display: block;
  }
}

.map__chart-perc {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
}

.map__chart-perc-text {
  @mixin max-line-length 3;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.map__chart-table-title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-left: 18px;
  padding-right: 32px;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--light-gray);
  margin-bottom: 16px;

  @mixin media 769 {
    padding-right: 0;
  }
}

.map__chart-table-title {
  font-size: 12px;
  font-weight: 500;
}

.map__chart-table-item-list {
  display: none;

  max-height: 130px;

  padding-right: 32px;
  padding-bottom: 5px;

  &.active {
    display: block;
  }

  @mixin media 769 {
    height: 100%;
    max-height: unset;

    /* padding-right: 0; */
    padding-right: 20px;
    margin-right: -20px;
  }

  .simplebar-vertical {
    width: 16px;

    border-radius: 999px;

    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.18);
  }

  .simplebar-scrollbar {
    &::before {
      background: var(--light-gray) !important;
    }
  }
}

.map__chart-table-item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: ac(12px, 6px);
    padding-bottom: ac(12px, 6px);
    border-bottom: 1px solid var(--light-gray);
  }

  &:nth-child(1) {
    --color: rgba(70, 34, 124, 1);
  }
  &:nth-child(2) {
    --color: rgba(185, 25, 170, 1);
  }
  &:nth-child(3) {
    --color: rgba(229, 20, 103, 1);
  }
  &:nth-child(4) {
    --color: rgba(34, 40, 55, 1);
  }
  &:nth-child(5) {
    --color: rgba(42, 250, 207, 1);
  }
}

.map__chart-table-item-title {
  position: relative;

  padding-left: 18px;

  font-size: 14px;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    border-radius: 50%;

    width: 8px;
    height: 8px;
    background: var(--color);
  }
}

.map__chart-table-item-percentage {
  position: relative;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  width: 48px;
  height: 24px;

  font-size: 14px;
  font-weight: 500;
  line-height: 1;

  border: 2px solid var(--color);
  border-radius: 4px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background: var(--color);
    opacity: 0.05;
  }
}

.map__open-perc-btn {
  display: none;

  width: perc(328, 360);
  max-width: 350px;

  margin: 0 auto;

  transition: setTransition(opacity);

  &:disabled {
    opacity: 0.2;

    cursor: not-allowed;
  }

  @mixin media 1025 {
    display: block;
  }
}

.follow-us {
  padding-top: ac(59px, 51px);
  padding-bottom: 40px;

  .cont {
    padding-top: 68px;

    border-top: 1px solid var(--light-gray);

    @mixin media 769 {
      padding-top: 0;
      border: none;
    }
  }

  &.no-line {
    @mixin media 769 {
      padding-top: 24px;
    }

    .cont {
      padding-top: 0;

      border-top: none;
    }
  }
}

.follow-us__title {
  margin-bottom: ac(67px, 32px);

  text-align: center;
}

.socials-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: ac(24px, 16px);

  max-width: 1080px;

  margin: 0 auto;

  @mixin media 769 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.social-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ac(48px, 24px) ac(58px, 24px);
  border: 1px solid var(--light-gray);
  border-radius: 16px;

  background: var(--white);

  transition: setTransition(box-shadow);

  @mixin media 769 {
    padding-bottom: 32px;
  }

  &:hover {
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.08),
      0px 0px 2px rgba(0, 0, 0, 0.25);
  }
}

.social-card__icon-wrap {
  position: relative;
  z-index: 1;

  padding: ac(6px, 2px);
  border-radius: 9999px;
  margin-bottom: ac(32px, 16px);

  &::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    z-index: -1;
    border-radius: 4px;
    border-radius: 9999px;

    background: var(--magenta-gr);

    transition: setTransition(opacity);
  }

  &::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    /* z-index: -1; */
    border-radius: 4px;
    border-radius: 9999px;

    background: var(--white);

    transition: setTransition(opacity);
  }
}

.social-card__icon {
  position: relative;
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  width: ac(88px, 54px);
  height: ac(88px, 54px);

  border: 1px solid var(--light-gray);
  border-radius: 9999px;

  font-size: ac(37px, 22px);

  color: var(--magenta);

  /* background: var(--magenta-gr);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent; */
  &.icon-linkedin {
    &::before {
      margin-top: -4px;
    }
  }
}

.social-card__title {
  margin-bottom: ac(24px, 24px);

  font-size: ac(24px, 18px);
  font-weight: 700;
}

.social-card__btn {
  display: flex;
  justify-content: center;
  align-items: center;

  max-width: 136px;
  width: 100%;

  padding: 3.5px 16px;

  border: 2px solid var(--light-gray);
  border-radius: 4px;

  background: var(--light-blue);

  transition: setTransition(border);

  &:hover {
    border-color: var(--magenta);
  }
}

.social-card__btn-text {
  font-size: 14px;

  background: var(--magenta-gr);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @mixin media 350 {
    font-size: 12px;
  }
}

.sort-by-select {
  display: flex;
  align-items: center;

  border-bottom: 1px solid var(--magenta);
  padding-left: 20px;

  &::before {
    content: "Sort by - ";

    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
    background: var(--magenta-gr);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .select2-selection {
    width: 175px;

    padding-left: 4px;
    border-bottom: none !important;
  }

  .select2-selection__rendered {
    padding-left: 0 !important;
    padding-right: 40px !important;

    font-weight: 500;
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: ac(63px, 29px);
}

.pagination__btn {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  border-radius: 4px;
  border: 1px solid var(--light-gray);

  background: #f3f5fb;

  font-size: 17px;

  transition: setTransition(border);

  &.prev {
    margin-right: ac(24px, 5px);
  }

  &.next {
    margin-left: ac(24px, 5px);
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:not(:disabled) {
    &:hover {
      border-color: var(--magenta);
    }

    i {
      background: var(--magenta-gr);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  @mixin media 360 {
    width: 32px;
    height: 32px;
  }
}

.pagination__page {
  width: 40px;
  height: 40px;

  border-radius: 4px;

  font-size: 16px;

  transition: setTransition(background);

  &:not(:last-child) {
    margin-right: ac(6px, 0px);
  }

  &:not(&.current):hover {
    background: var(--light-gray);
  }

  &.current {
    color: var(--white);
    background: var(--magenta-gr);

    font-weight: 500;
  }

  @mixin media 360 {
    width: 32px;
    height: 32px;
  }
}

.gallery-swiper__wrap {
  display: flex;
  flex-direction: column;

  /* max-height: 510px; */
  height: 100%;

  &.who-we-are__gallery {
    height: 578px;
    max-height: unset;
  }

  @mixin media 769 {
    position: relative;

    border-radius: 16px;
    /* overflow: hidden; */

    &.who-we-are__gallery {
      height: unset;
    }
  }
}

.gallery-swiper__top-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;

  max-width: 100%;

  padding: ac(21.5px, 10px) ac(24px, 16px) ac(21.5px, 10px) ac(48px, 18px);

  background: var(--white);

  @mixin media 769 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transform: translateY(-50%);

    border-radius: 16px;
    border: 1px solid var(--light-gray);
  }
}

.gallery-swiper__nav {
  display: flex;

  margin-left: 10px;
}

.gallery-swiper__nav-btn {
  position: relative;
  /* overflow: hidden; */

  display: flex;
  justify-content: center;
  align-items: center;

  width: ac(48px, 40px);
  min-width: auto;
  height: ac(48px, 40px);

  padding: 0;
  border: 1px solid var(--light-gray);
  border-radius: 4px;

  color: var(--white);
  transition: setTransition(color);

  &:not(:last-child) {
    margin-right: 8px;
  }

  &::before {
    content: "";
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;

    border-radius: 4px;

    background: var(--magenta-gr);

    transition: setTransition(opacity);
  }

  &:hover {
    color: var(--magenta);

    &::before {
      opacity: 0;
    }
  }

  i {
    position: relative;
    z-index: 1;

    font-size: 22px;
  }

  &.prev {
    color: var(--magenta);

    &::before {
      opacity: 0;
    }

    &:hover {
      color: var(--white);

      &::before {
        opacity: 1;
      }
    }
  }
}

.gallery-swiper-title {
  overflow: hidden;

  font-size: ac(24px, 22px);
  font-weight: 700;

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-slide {
    @mixin max-line-length 2;
    line-height: 1.1818;
  }
}

.gallery-swiper-img {
  overflow: hidden;
  clip-path: inset(1px round 16px);

  width: 100%;
  /* max-height: 419px; */
  height: auto;
  flex-grow: 1;

  .swiper-slide {
    height: auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @mixin media 769 {
    &:not(.who-we-are__gallery .gallery-swiper-img) {
      z-index: 0;

      height: 328px;
    }
  }
}

.squad {
  padding-top: ac(64px, 32px);
  padding-bottom: ac(64px, 48px);

  .cont {
    position: relative;

    padding-top: ac(82px, 40px);
    padding-bottom: 77px;
    border-top: 1px solid var(--light-gray);
    border-bottom: 1px solid var(--light-gray);

    @mixin media 769 {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  &.no-top-line {
    .cont {
      border-top: none;
      padding-top: 0;
    }
  }
}

.squad__top-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: ac(64px, 39px);
}

.squad__title {
  margin-bottom: 0;
}

.squad-swiper {
  margin-bottom: ac(64px, 31px);

  .swiper-slide {
    max-width: 334px;
    height: auto;

    @mixin media 551 {
      max-width: 248px;
    }

    .teammate-card {
      height: 100%;

      .teammate-card__btn {
        margin-top: auto;
      }
    }
  }
}

.squad__join-btn {
  width: 100%;
  max-width: 224px;

  margin: 0 auto;

  @mixin media 769 {
    max-width: 315px;
  }
}

.teammate-card {
  position: sticky;
  position: -webkit-sticky;
  top: 100px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: ac(40px, 22px) ac(30px, 24px) ac(56px, 32px);
  border: 1px solid var(--light-gray);
  border-radius: 16px;

  background: var(--white);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);

  &.job-page {
    padding: ac(47px, 32px) ac(54px, 36px) ac(46px, 32px);

    .teammate-card__name {
      font-size: 24px;

      @mixin media 769 {
        margin-bottom: 8px;

        font-size: 26px;
      }
    }

    .teammate-card__position {
      font-size: ac(18px, 16px);
    }

    .teammate-card__img {
      @mixin media 769 {
        margin-bottom: 17px;
      }
    }

    .teammate-card__btn {
      @mixin media 769 {
        width: 100%;
      }
    }
  }
}

.teammate-card__img {
  width: 100%;
  max-width: 227px;

  margin-bottom: 13px;

  @mixin media 769 {
    width: 100%;

    max-width: 275px;

    margin-bottom: 8px;
  }
}

.teammate-card__name {
  margin-bottom: 8px;

  font-size: ac(26px, 20px);
  font-weight: 700;
  text-align: center;

  @mixin media 769 {
    margin-bottom: 5px;
  }
}

.teammate-card__position {
  margin-bottom: 16px;

  background: var(--magenta-gr);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: ac(20px, 16px);
  text-align: center;
}

.teammate-card__socials-list {
  display: flex;

  margin-bottom: ac(30px, 24px);
}

.teammate-card__social {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border: 1px solid var(--light-gray);
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.16);
  border-radius: 50%;

  font-size: 14px;

  .icon-mail {
    font-size: 23px;
  }
}

.teammate-card__social {
  position: relative;

  /* background: var(--magenta-gr);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent; */

  color: var(--magenta);

  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.16);

  transition: setTransition(border);

  &:not(:last-child) {
    margin-right: 32px;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: calc(100% + 16px);
      transform: translateX(-50%);

      width: 1px;
      height: 100%;

      background: var(--light-gray);

      pointer-events: none;
    }
  }

  &:hover {
    border-color: var(--magenta);
  }
}

.teammate-card__btn {
  width: 160px;
  padding: 0;
}

.vacancies-slider {
  overflow: hidden;

  padding: ac(64px, 30px) 0;

  background: var(--light-blue);

  .cont {
    position: relative;

    display: flex;
    align-items: center;

    @mixin media 1025 {
      flex-direction: column;
      align-items: stretch;
    }
  }

  .job-card {
    @mixin media 769 {
      padding-left: 23px;

      .job-card__descr {
        margin-bottom: 10px;
        margin-top: 12px;
        padding-top: 13px;
      }

      .job-card__descr-text {
        @mixin max-line-length 5;
      }
    }
  }
}

.vacancies-slider__text-wrap {
  position: relative;
  z-index: 1;

  max-width: 360px;

  margin-right: ac(85px, 20px);

  @mixin media 1025 {
    max-width: 500px;

    margin-right: 0;
    margin-bottom: 33px;
  }
}

.vacancies-slider__title {
  margin-bottom: ac(24px, 13px);

  font-size: ac(48px, 34px);
}

.vacancies-slider__descr {
  margin-bottom: ac(32px, 15px);

  font-size: ac(18px, 14px);

  @mixin media 769 {
    line-height: 1.4;
  }
}

.vacancies-swiper {
  max-width: 413px;

  @mixin media 651 {
    max-width: 312px;
  }

  .swiper-slide {
    overflow: visible !important;

    height: auto;

    .job-card {
      height: 100%;
    }
  }
}

.part-of-community {
  &.similar-blogs {
    .cont {
      padding: ac(64px, 16px) ac(64px, 24px);
    }

    .part-of-community__title {
      font-size: ac(48px, 26px);
    }

    .part-of-community__descr {
      font-size: ac(18px, 14px);
    }

    .community-swiper {
      @mixin media 769 {
        max-width: 265px;
      }
    }
  }
}

.cookie-terms-section {
  padding-top: 100px;
}

.page-404 {
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 100vh;

  padding: ac(100px, 20px);

  background: var(--light-blue);
}

.page-404__title {
  position: relative;
  z-index: 1;

  margin-bottom: 20px;

  text-align: center;
}

.page-404__text {
  position: relative;
  z-index: 1;

  font-size: 20px;
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

.page-404__decor {
  position: absolute;
  right: 0;
  transform: translate(25%);
}

.case-study-coming-section {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  min-height: 100vh;

  .cont {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.case-study-coming-title {
  margin-bottom: 20px;

  font-family: var(--font-main);
  text-align: center;
}

.select2-results__option {
  font-size: 14px;
}
