.insights {
  padding-top: ac(64px, 37px);
  padding-bottom: ac(65px, 32px);
}

.insights__filters {
  border-bottom: 1px solid var(--light-gray);
  padding-bottom: 32px;

  @mixin media 601 {
    border-bottom: none;
  }
}

.insights__title {
  margin-bottom: ac(32px, 14px);

  font-size: ac(36px, 26px);
  font-weight: 700;
  line-height: 1.34;
}

.insights__top-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @mixin media 1025 {
    flex-direction: column;
    align-items: flex-start;
  }
}

.insights__tag-list {
  @mixin media 1025 {
    width: 100%;

    margin-bottom: 41px;
  }

  .swiper-slide {
    width: fit-content;

    &:not(:last-child) {
      .tag-item {
        padding-right: ac(8px, 6px);
        border-right: 1px solid var(--light-gray);
      }
    }
  }
}

.tag-item {
  display: flex;
  align-items: center;

  cursor: pointer;

  color: var(--gray);

  font-weight: 500;

  transition: setTransition(color);

  &:hover {
    color: var(--magenta);
  }

  .tag-item__checkbox:checked ~ .tag-item__title {
    &::after {
      opacity: 1;
    }
  }
}

.tag-item__checkbox {
  display: none;
}

.tag-item__title {
  position: relative;

  padding: ac(8px, 4px) ac(24px, 15px);

  font-size: 16px;

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 4px;
    padding: 2px;
    z-index: -1;
    background: var(--magenta-gr);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    opacity: 0;

    transition: setTransition(opacity);
  }
}

.insights-result {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: ac(40px, 32px) ac(30px, 24px);

  margin-bottom: 64px;
  padding-top: 40px;

  @mixin media 901 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @mixin media 601 {
    padding-top: 0;

    grid-template-columns: minmax(0, 1fr);
  }

  .article-card {
    .article-card__text-wrap {
      padding: ac(18px, 10px) ac(32px, 22px) ac(40px, 25px) ac(32px, 29px);
    }

    .article-card__title-wrap {
      margin-bottom: 13px;
      /* justify-content: flex-start; */
    }

    .article-card__title {
      @mixin media 769 {
        font-size: 18px;
      }
    }

    .article-card__img {
      @mixin media 601 {
        height: 187px;
      }
    }

    .article-card__descr-wrap {
      margin-bottom: ac(16px, 8px);
    }
  }
}

.insights-result__not-found {
  grid-column: 1 / 4;

  margin-bottom: -64px;

  text-align: center;

  @mixin media 901 {
    grid-column: 1 / 3;
  }

  @mixin media 601 {
    grid-column: 1 / 2;
  }
}

.insights-details {
  padding-top: ac(64px, 30px);
  padding-bottom: 64px;

  overflow: visible;

  .cont {
    padding-bottom: 64px;
    border-bottom: 1px solid var(--light-gray);

    @mixin media 769 {
      width: perc(312, 360);
    }
  }
}

.insights-details__content-wrap {
  display: flex;

  margin-bottom: ac(48px, 20px, 1025);

  @mixin media 1025 {
    flex-direction: column;
  }
}

.insights-details__content {
  width: 100%;

  margin-right: ac(53px, 20px);

  @mixin media 1025 {
    margin-right: 0;

    margin-bottom: 20px;
  }
}

.insights-details__short-content {
  width: 100%;
  max-width: 423px;

  @mixin media 1025 {
    margin: 0 auto;
  }
}

.insights-details__short-content-inner-wrap {
  position: sticky;
  position: -webkit-sticky;
  top: 100px;
  overflow-x: hidden;

  max-height: calc(100vh - 100px);

  padding-bottom: 50px;

  @mixin media 1025 {
    top: 0;

    max-height: unset;

    padding-bottom: 0;
  }
}

.insights-details__short-content-title {
  margin-bottom: 24px;
  padding-left: 16px;

  font-size: 24px;
  font-weight: 700;

  @mixin media 1025 {
    display: none;
  }
}

.insights-details__heading-list {
  padding-left: 16px;
  margin-bottom: 75px;
  border-left: 1px solid var(--light-gray);

  @mixin media 1025 {
    display: none;
  }
}

.insights-details__heading {
  display: block;

  color: var(--gray);

  transition: setTransition(color);
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  &:hover {
    color: var(--magenta);
  }
}

.insights-details__subscription-card {
  padding: ac(48px, 20px) ac(40px, 20px);
  border: 1px solid var(--light-gray);
  border-radius: 8px;

  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
}

.insights-details__subscription-title {
  margin-bottom: 32px;

  font-size: 24px;
  font-weight: 700;
  line-height: 1.34;
}

.insights-details__subscription-input {
  margin-bottom: 24px;
}

.btn.insights-details__subscription-btn {
  width: 100%;
  max-width: 224px;

  margin-bottom: 16px;
}

.insights-details__subscription-privacy {
  font-size: 12px;
  font-weight: 500;

  a {
    position: relative;

    font-size: 12px;

    background: var(--magenta-gr);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &::after {
      content: "";
      position: absolute;
      bottom: 0.2em;
      left: 0;

      width: 100%;
      height: 0.5px;

      background: var(--magenta);

      transition: setTransition(opacity);
    }

    &:hover {
      &::after {
        opacity: 0;
      }
    }
  }
}
