.first-section__candidate-decor {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(23%, -50%);

  width: ac(479px, 400px);

  @mixin media 769 {
    top: 37%;

    width: 240px;

    mask-image: linear-gradient(
      180deg,
      #d9def2 64.14%,
      rgba(228, 232, 246, 0) 75.55%
    );
  }
}

.job-filters {
  padding: ac(64px, 55px) 0 ac(64px, 45px);
  border-top: 1px solid var(--light-gray);
}

.job-filters__title {
  margin-bottom: ac(45px, 31px);

  text-align: center;
}

.job-filters__form {
  max-width: 740px;

  margin: 0 auto;
}

.job-filters__form-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 25px;

  margin-bottom: ac(40px, 32px);

  @mixin media 551 {
    grid-template-columns: minmax(0, 1fr);
    grid-gap: 24px;

    padding: 0 18px;

    .select2-container {
      max-width: 100%;
    }
  }

  .grid-two-column {
    grid-column: 1 / 3;

    @mixin media 551 {
      grid-column: 1 / 2;
    }
  }
}

.job-filters__form-btn {
  min-width: 208px;

  margin: 0 auto;

  @mixin media 551 {
    width: 100%;
  }
}

.jobs-search-result {
  padding: 32px 0 60px;

  border-top: 1px solid var(--light-gray);

  @mixin media 769 {
    padding: 0;
    border: none;
  }
}

.jobs-search-result__top-wrap {
  border-bottom: 1px solid var(--light-gray);
  padding-bottom: 32px;

  @mixin media 601 {
    border-bottom: none;
    padding-bottom: 0;
  }

  .cont {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @mixin media 601 {
      flex-direction: column;
    }
  }
}

.jobs-search-result__title {
  margin-bottom: 0;

  font-size: ac(36px, 26px);
  line-height: 1;

  @mixin media 601 {
    margin-bottom: 20px;
  }
}

.jobs-search-result__list {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: ac(40px, 12px) ac(30px, 16px);

  padding-top: ac(44px, 20px);

  @mixin media 1025 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @mixin media 651 {
    grid-template-columns: minmax(0, 1fr);
  }
}

.job-card {
  display: flex;
  flex-direction: column;

  padding: ac(32px, 24px);
  border: 1px solid #e6e6e6;
  border-radius: 16px;

  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  background: #ffffff;

  font-weight: 400;

  transition: setTransition(border);

  @mixin media 769 {
    padding-top: 26px;
    padding-bottom: 32px;
    padding-left: 26px;
  }

  &:hover {
    border-color: var(--magenta);
  }
}

.job-card__title-wrpa {
  display: flex;
  align-items: center;

  height: ac(96px, 64px);

  margin-bottom: 8px;

  @mixin media 769 {
    margin-bottom: 16px;
  }
}

.job-card__title {
  @mixin max-line-length 2;

  font-size: ac(24px, 22px);
  font-weight: 700;
  line-height: 1.34;
}

.job-card__location,
.job-card__type,
.job-card__salary {
  display: flex;
  align-items: center;

  font-size: ac(16px, 14px);
  line-height: 1.6;
}

.job-card__icon {
  margin-right: 8px;

  background: var(--magenta-gr);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: 24px;
}

.job-card__type-salary-wrap {
  display: flex;
  flex-wrap: wrap;

  margin-top: 16px;

  margin-right: -17.5px;
  margin-bottom: -17.5px;
}

.job-card__type {
  margin-right: 17.5px;
  margin-bottom: 17.5px;

  &:not(:last-child) {
    padding-right: ac(16.5px, 13px);
    border-right: 1px solid var(--light-gray);
  }
}

.job-card__salary {
  margin-right: 17.5px;
  margin-bottom: 17.5px;
}

.job-card__descr {
  margin-bottom: 16px;
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid var(--light-gray);

  @mixin media 769 {
    margin-top: 15px;
    padding-top: 21px;
    margin-bottom: 22px;
  }
}

.job-card__descr-text {
  @mixin max-line-length 4;

  line-height: 1.6;

  @mixin media 769 {
    line-height: 1.4;
  }
}

.job-card__btn-wrap {
  margin-top: auto;
}

.job-card__btn {
  min-width: 200px;
}

.testimonials {
  padding-top: ac(60px, 55px);
  padding-bottom: ac(57px, 0px);

  .cont {
    padding-bottom: ac(64px, 0px);
    border-bottom: 1px solid var(--light-gray);

    @mixin media 769 {
      border: none;
    }
  }
}

.testimonials__top-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: ac(71px, 32px);
}

.testimonials__title {
  margin-bottom: 0;
}

.testimonials-swiper {
  max-width: 862px;
  width: 100%;

  margin: 0 auto;

  .swiper-slide {
    background: var(--light-blue);
    border-radius: 16px;

    &:not(&.swiper-slide-active) {
      .testimonials-card {
        opacity: 0;
      }
    }
  }
}

.testimonials-card {
  display: flex;

  padding: 16px ac(22px, 16px);
  border-radius: 16px;

  color: var(--white);
  background: var(--navy);

  transition: setTransition(opacity);

  @mixin media 769 {
    padding-top: 24px;
  }
  @mixin media 651 {
    flex-direction: column;
  }
}

.testimonials-card__info-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 41%;

  padding-right: ac(20px, 0px);

  @mixin media 651 {
    flex-direction: row;

    width: 100%;

    margin-bottom: ac(16px, 13px);
  }

  @mixin media 355 {
    flex-direction: column;
  }
}

.testimonials-card__info {
  @mixin media 651 {
    margin-right: 19px;
  }

  @mixin media 401 {
    margin-right: 5px;
    margin-bottom: 0;
    padding-left: 11px;
  }

  @mixin media 355 {
    text-align: center;
    margin-right: 0;
  }
}

.testimonials-card__name {
  margin-bottom: ac(6px, 2px);

  font-size: ac(24px, 22px);
  font-weight: 700;
}

.testimonials-card__position {
  width: fit-content;

  margin-bottom: 41px;

  background: linear-gradient(308.7deg, #2afacf 22.51%, #2f4596 108.56%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: 16px;
  font-weight: 500;

  @mixin media 651 {
    margin-bottom: 0;

    br {
      display: none;
    }
  }
}

.testimonials-card__logo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 90%;
  max-width: 233px;

  &::before,
  &::after {
    content: "";
    display: block;

    width: ac(16px, 12px);
    height: 2px;

    background: rgba(255, 255, 255, 0.1);
  }

  @mixin media 651 {
    max-width: 134px;
  }
}

.testimonials-card__logo {
  max-width: 168px;
  max-height: 98px;

  margin: 0 ac(16px, 8px);

  filter: grayscale(1) opacity(1) brightness(0) invert(1);

  @mixin media 651 {
    max-width: 93px;
    max-height: 55px;
  }
}

.testimonials-card__text-wrap {
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  width: 59%;

  padding: ac(88px, 32px) ac(48px, 24px) 40px;
  border-radius: 8px;

  background: linear-gradient(0deg, #383d4b, #383d4b);

  &::before,
  &::after {
    content: "";
    position: absolute;

    width: 108px;
    height: 96px;

    display: block;

    background-size: contain;
    background-image: url("../images/quotes-dark.svg");
    background-repeat: no-repeat;
  }

  &::before {
    left: 0;
    top: ac(-20px, -8px);

    transform: rotate(180deg);
  }

  &::after {
    right: 0;
    bottom: ac(-20px, -8px);
  }

  @mixin media 651 {
    width: 100%;
  }
}

.testimonials-card__text {
  position: relative;
  z-index: 2;

  max-height: 190px;

  margin-bottom: ac(55px, 24px);

  font-size: ac(17px, 16px);
  font-weight: 400;
  line-height: 1.6;

  * {
    font-size: ac(17px, 16px);
    font-weight: 400;
    line-height: 1.6;
    color: var(--white);
  }

  @mixin media 651 {
    max-height: 312px;

    text-align: center;
  }
}

.testimonials-card__btn {
  min-width: 200px;

  margin-top: auto;

  @mixin media 651 {
    margin: auto auto 0;
  }
}

/* JOB PAGE */
.first-section__job-details {
  margin-bottom: 22px;
}

.first-section__job-details-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  margin-right: ac(-32px, -16px);
  margin-bottom: ac(-32px, -16px);
}

.first-section__job-details-items-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.first-section__job-details-item {
  display: flex;
  align-items: center;

  margin-right: ac(32px, 16px);
  margin-bottom: ac(32px, 16px);

  font-size: ac(20px, 14px);

  &:not(:last-child) {
    margin-right: 32px;
  }

  i {
    margin-right: 8px;

    font-size: ac(32px, 24px);

    background: var(--magenta-gr);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.btn.first-section__job-details-btn {
  @mixin media 769 {
    width: 200px;
  }
}

.job-details {
  padding-top: ac(68px, 33px);

  overflow: visible;

  .cont {
    @mixin media 769 {
      width: perc(312, 360);
    }
  }
}

.job-details__title {
  margin-bottom: 18px;

  @mixin media 769 {
    margin-bottom: 17px;
  }
}

.job-details__content-wrap {
  display: flex;
  justify-content: space-between;

  @mixin media 769 {
    flex-direction: column;
  }
}

.job-details__content-share {
  width: 100%;

  margin-right: ac(60px, 15px);

  @mixin media 769 {
    margin-bottom: 32px;
  }
}

.job-details__content {
  margin-bottom: 48px;
}

.job-details__teammate {
  width: 100%;
  max-width: 313px;

  @mixin media 769 {
    max-width: 450px;

    margin: 0 auto;
  }
}

.job-details__share-wrap {
  display: flex;
  align-items: center;

  width: fit-content;

  padding-right: 55px;
  padding-bottom: 32px;
  margin-bottom: ac(32px, 11px);

  border-bottom: 1px solid var(--light-gray);
}

.job-details__share-title {
  margin-right: 24px;

  font-size: 16px;

  @mixin media 769 {
    font-size: 22px;
    font-weight: 500;
  }
}

.job-details__share-list {
  display: flex;
}

.job-details__share-item {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border: 1px solid var(--light-gray);
  border-radius: 50%;

  font-size: 13px;

  cursor: pointer;

  transition: setTransition(color);

  &:not(:last-child) {
    margin-right: ac(16px, 8px);
  }

  @mixin media 901 {
    width: 40px;
    height: 40px;

    font-size: 16px;
  }

  i {
    position: relative;
    z-index: 1;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    border-radius: 50%;

    background: var(--magenta-gr);

    opacity: 0;
    transform: scale(0.8);

    transition: setTransition(400ms, opacity, transform);
  }

  &:hover {
    color: var(--white);

    &::before {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.job-details__apply-btn {
  @mixin media 769 {
    width: 100%;
    max-width: 312px;
  }
}
