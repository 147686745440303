:root {
  --black: #161c2c;
  --navy: #222837;
  --gray: #4e525d;
  --light-gray: #e6e6e6;
  --light-blue: #f3f5fb;
  --beige: #e6e6e6;
  --white: #ffffff;
  --magenta: #b919aa;
  --cyan: #2afacf;

  --magenta-gr: linear-gradient(167deg, #b919aa 24.08%, #46227c 117.07%);
  --magenta-gr-lr: linear-gradient(
    90deg,
    #b919aa 14.22%,
    #7f1e93 25.03%,
    #46227c 33.46%,
    #46227c 33.46%
  );
  --magenta-2-gr: linear-gradient(359.57deg, #b919aa -0.39%, #2f4596 99.37%);
  --cyan-gr: linear-gradient(41.15deg, #2afacf 15.63%, #2f4596 79.53%);
  --pink-gr: linear-gradient(42.66deg, #b919aa 15.3%, #e51467 83.55%);

  --font-main: "effra", sans-serif;

  --header-h: ac(95px, 75px);
  --scrolled-header-h: ac(70px, 60px);
  /* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
  --mobile-menu-start-point: 1025;
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
@custom-media --mobile-menu-start-point (max-width: 1025px);

/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
@custom-media --mobile-menu-end-point (min-width: 1024px);
