.btn {
  position: relative;
  z-index: 1;

  /* display: block;
  text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;

  width: fit-content;
  min-width: 160px;
  height: 40px;

  padding: 8px 40px;

  color: var(--white);
  background: transparent;
  border-radius: 4px;
  /* overflow: hidden; */

  box-shadow: 0px 3px 4px 0px #00000029;
  transition: setTransition(color);

  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  /* line-height: 1.2; */

  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border-radius: 4px;

    background: var(--magenta-gr);

    transition: setTransition(opacity);
  }

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 4px;
    padding: 2px;
    z-index: -1;
    background: var(--magenta-gr);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  &:not(:disabled):hover {
    color: var(--black);

    &::before {
      opacity: 0;
    }
  }

  &.btn--invert {
    color: var(--black);

    &::before {
      opacity: 0;
    }

    &:hover {
      color: var(--white);

      &::before {
        opacity: 1;
      }
    }

    &.btn--arrow {
      &:hover {
        .btn__arrow {
          color: var(--white);

          &::before {
            opacity: 0;
          }

          &::after {
            opacity: 1;
          }
        }
      }

      .btn__arrow {
        color: var(--magenta);

        &::before {
          opacity: 1;
        }

        &::after {
          opacity: 0;
        }
      }
    }
  }

  &.btn--white {
    color: var(--black);

    &::before {
      background: var(--white);
    }

    &::after {
      background: var(--white);
    }

    &:hover {
      color: var(--white);
    }

    &.btn--arrow {
      &:hover {
        .btn__arrow {
          color: var(--white);

          &::before {
            opacity: 0;
          }

          &::after {
            opacity: 1;
          }
        }
      }

      .btn__arrow {
        color: var(--magenta);

        &::before {
          opacity: 1;
          background: var(--light-gray);
        }

        &::after {
          opacity: 0;
        }
      }
    }
  }

  &.btn--arrow {
    padding: 0;
    justify-content: end;

    .btn__text {
      width: 100%;

      padding: 0 ac(23.5px, 20px);

      text-align: center;
    }

    .btn__arrow {
      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;

      flex-shrink: 0;

      width: 40px;
      height: 100%;

      color: var(--white);

      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 0;

        width: 2px;
        height: 100%;

        transition: setTransition(opacity);
      }

      &::before {
        background: var(--magenta-gr);
        opacity: 0;
      }

      &::after {
        background: var(--white);
      }

      &.left {
        &::before,
        &::after {
          right: 0;
          left: unset;
        }
      }
    }

    &:hover {
      .btn__arrow {
        color: var(--magenta);

        &::before {
          opacity: 1;
        }

        &::after {
          opacity: 0;
        }
      }
    }
  }
}
