.geting-started {
  padding: ac(80px, 42px) 0 ac(64px, 24px);

  .cont {
    position: relative;

    padding: ac(64px, 24px);

    &::before,
    &::after {
      content: "";
      position: absolute;

      width: 50%;
      height: 1px;

      background: var(--light-gray);
    }

    &::before {
      top: 0;
      right: 0;
    }

    &::after {
      bottom: 0;
      left: 0;
    }

    @mixin media 769 {
      padding: 24px 0;

      &::before,
      &::after {
        width: 100%;
      }
    }
  }
}

.geting-started__content-wrap {
  display: flex;
  align-items: center;

  @mixin media 769 {
    flex-direction: column;
  }
}

.geting-started__text-wrap {
  width: 50%;

  padding-right: ac(64px, 20px);

  @mixin media 769 {
    width: 100%;

    margin-bottom: 32px;
    padding: 0 9px;
  }
}

.geting-started__title {
  margin-bottom: ac(24px, 20px);
}

.geting-started__descr {
  margin-bottom: ac(24px, 18px);

  * {
    font-size: ac(20px, 16px);
    font-weight: 700;
    line-height: 1.6;
  }
}

.geting-started__text {
  max-height: 222px;

  @mixin media 769 {
    max-height: unset;
  }
}

.geting-started__img-wrap {
  position: relative;
  overflow: hidden;

  width: 50%;
  height: 456px;

  border: 1px solid var(--light-gray);
  border-radius: 16px;

  @mixin media 769 {
    width: 100%;
    height: 262px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

.geting-started__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.geting-started__img-logo-wrap {
  position: absolute;
  bottom: -1px;
  left: 0;
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 96px;

  padding: 10px;
  border-radius: 16px;

  background: var(--white);
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.08),
    0px -4px 16px rgba(0, 0, 0, 0.14);

  &::before,
  &::after {
    content: "";

    flex-grow: 1;
    max-width: ac(58px, 40px);
    height: 1px;

    background: var(--light-gray);
  }

  @mixin media 769 {
    height: 72px;
  }
}

.geting-started__img-logo {
  width: 100%;
  max-width: ac(196px, 160px);

  padding: 0 16px;
}

.reason {
  overflow: hidden;

  margin-bottom: 80px;

  .cont {
    overflow: hidden;

    border: 1px solid var(--light-gray);
    border-left: none;
    border-radius: 0 16px 16px 0;

    @mixin media 769 {
      position: relative;

      clip-path: inset(0);

      padding-bottom: ac(336px, 253px, 360, 769);
      padding-top: 32px;
      border: 1px solid #e6e6e6;
      border-radius: 16px;

      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
    }
  }
}

.reason__bg-img-wrap {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  width: 50vw;
  height: calc(100% - 4px);

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(90deg, #b919aa 0%, rgba(22, 28, 44, 0) 122.19%),
    linear-gradient(323.64deg, #2f4596 -156%, #2afacf 108.71%), #ffffff;

  @mixin media 769 {
    bottom: 0;
    top: unset;
    left: 50%;
    transform: translateX(-50%);

    width: 100vw;
    height: ac(336px, 261px, 360, 769);
  }
}

.reason__bg-img {
  width: 100%;
  height: 100%;
  object-fit: cover;

  mix-blend-mode: soft-light;

  @mixin media 769 {
    object-position: center 10%;
  }
}

.reason__content-wrap {
  position: relative;
  z-index: 1;

  width: 45%;

  margin-left: auto;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 54%;
    transform: translate(-50%, -50%);
    z-index: -1;

    width: 912px;
    height: 912px;

    border-radius: 9999px;

    background: var(--white);
  }

  @mixin media 1025 {
    width: 55%;
  }

  @mixin media 769 {
    width: 100%;

    padding: 0 24px;

    &::before {
      top: 0;
      left: 50%;
      transform: translate(-50%, -3%);

      width: ac(2000px, 1198px, 360, 769);
      height: ac(2000px, 1198px, 360, 769);

      @mixin media 769 {
        top: unset;
        bottom: 0;
        transform: translate(-50%, 3%);
      }
    }
  }
}

.reason__content {
  padding: 64px ac(174px, 50px) 52px 0;

  @mixin media 769 {
    max-width: 415px;

    margin: 0 auto;
    padding: 0;
  }

  ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    max-height: 234px;

    @mixin media 769 {
      max-height: unset;
    }
  }

  li {
    position: relative;
    max-width: 190px;

    font-size: 16px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    padding-left: 30px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0.25em;
      /* transform: translateY(-50%); */

      font-size: 18px;

      display: block;
      width: 16px;
      height: 16px;

      background: url("../images/li-point.svg");
      background-size: contain;
      background-repeat: no-repeat;
    }

    /* &::after {
      content: ";";
    }

    &:last-child {
      &::after {
        content: ".";
      }
    } */

    @mixin media 769 {
      max-width: unset;
    }
  }

  .results-swiper {
    max-width: 288px;

    @mixin media 651 {
      max-width: 226px;
    }

    .swiper-slide {
      overflow: visible !important;

      height: auto;
    }
  }
}

.reason__title {
  margin-bottom: ac(30px, 9px);
}

.reason__text {
  margin-bottom: ac(29px, 24px);

  font-size: ac(20px, 16px);
  font-weight: 700;

  @mixin media 769 {
    line-height: 1.6;
  }
}

.reason__list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  max-height: 234px;

  @mixin media 769 {
    max-height: unset;
  }
}

.reason__item {
  position: relative;
  max-width: 190px;

  font-size: 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  padding-left: 30px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    display: block;
    width: 16px;
    height: 16px;

    background: url("../images/li-point.svg");
    background-size: contain;
    background-repeat: no-repeat;
  }

  &::after {
    content: ";";
  }

  &:last-child {
    &::after {
      content: ".";
    }
  }

  @mixin media 769 {
    max-width: unset;
  }
}

.results-swiper {
  max-width: 288px;

  @mixin media 651 {
    max-width: 226px;
  }

  .swiper-slide {
    overflow: visible !important;

    height: auto;
  }
}

.result-card {
  display: flex;
  flex-direction: column;

  height: 100%;

  border: 1px solid #e6e6e6;
  border-radius: 16px;
  padding: ac(32px, 27px) 24px ac(71px, 41px);

  background: var(--white);

  text-align: center;

  transition: setTransition(box-shadow);

  &:hover {
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.12);
  }
}

.result-card__icon-wrap {
  position: relative;
  z-index: 1;

  width: fit-content;

  padding: 8px;
  border-radius: 9999px;
  margin: 0 auto 16px;

  &::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    z-index: -1;
    border-radius: 4px;
    border-radius: 9999px;

    background: var(--magenta-gr);

    transition: setTransition(opacity);
  }

  &::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    /* z-index: -1; */
    border-radius: 4px;
    border-radius: 9999px;

    background: var(--white);

    transition: setTransition(opacity);
  }
}

.result-card__icon {
  position: relative;
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 64px;
  height: 64px;

  border: 1px solid var(--light-gray);
  padding: 8px;
  border-radius: 9999px;

  font-size: ac(37px, 22px);

  background: var(--magenta-gr);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.result-card__title {
  width: 90%;

  margin: 0 auto ac(42px, 16px);

  font-size: ac(24px, 22px);
  font-weight: 700;
  line-height: 1.34;
}

.result-card__descr {
  margin-top: auto;

  font-size: 16px;
}

.part-of-community.results {
  .cont {
    padding: ac(80px, 14px) ac(73px, 22px);
  }

  .part-of-community__text-wrap {
    max-width: 427px;

    margin-right: 17px;
  }

  .part-of-community__title {
    font-size: ac(48px, 34px);
  }

  .part-of-community__descr {
    margin-bottom: 14px;

    font-size: ac(18px, 14px);
    line-height: 1.6;

    @mixin media 769 {
      line-height: 1.4;
    }
  }
}
