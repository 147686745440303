.why-synchro {
  padding-top: ac(80px, 40px);
  padding-bottom: 32px;
}

.why-synchro__content-wrap {
  overflow: hidden;

  display: flex;

  border-radius: 16px;
  border: 1px solid var(--light-gray);

  @mixin media 769 {
    flex-direction: column;
    border: none;
    border-radius: 0;
  }
}

.why-synchro__left-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 50%;

  padding: ac(94px, 27px) ac(64px, 23px);

  background: var(--light-blue);

  @mixin media 769 {
    width: 100%;

    margin-bottom: 63px;
    border-radius: 16px;
    border: 1px solid var(--light-gray);
  }
}

.why-synchro__title {
  margin-bottom: ac(24px, 12px);
}

.why-synchro____descr {
  margin-bottom: ac(40px, 20px);

  font-size: ac(20px, 16px);

  @mixin media 769 {
    font-weight: 700;

    padding-bottom: 31px;
    border-bottom: 1px solid var(--light-gray);

    br {
      display: none;
    }
  }
}

.why-synchro__additional-text {
  font-size: 16px;

  @mixin media 769 {
    line-height: 1.6;
  }
}

.why-synchro__right-wrap {
  width: 50%;

  @mixin media 769 {
    /* overflow: hidden; */

    width: 100%;

    border-radius: 16px;
  }
}

.life-in-synchro {
  padding-top: ac(125px, 61px);
  padding-bottom: 28px;
}

.life-in-synchro__title {
  margin-bottom: ac(18px, 9px);

  text-align: center;
}

.life-in-synchro__descr {
  margin-bottom: ac(64px, 32px);

  font-size: ac(18px, 14px);
  text-align: center;
}

.gallery-on-scroll {
  position: relative;
  margin: 0 auto;
  max-width: 1920px;
  z-index: 1;

  .wrapper {
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .row {
    margin-bottom: 2vw;

    &:nth-child(even) {
      .img {
        &:nth-child(3n + 3) {
          width: ac(384px, 188px);
        }
      }
    }

    &:nth-child(odd) {
      .img {
        &:nth-child(4n + 4) {
          width: ac(384px, 188px);
        }
      }
    }
  }

  .img-box {
    display: flex;
  }

  .img {
    flex: 0 0 auto;
    width: ac(650px, 312px);
    height: ac(394px, 188px);
    margin-right: 2vw;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 16px;
    }
  }
}

.teammate-testimonials {
  padding-top: 9px;
  padding-bottom: 18px;

  .cont {
    position: relative;

    padding-top: ac(64px, 30px);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      width: 100%;
      max-width: 1172px;
      height: 1px;

      background: var(--light-gray);
    }

    @mixin media 769 {
      padding-top: 0;

      &::before {
        display: none;
      }
    }
  }
}

.teammate-testimonials-swiper {
  max-width: 552px;
  width: 100%;

  margin: 0 auto ac(62px, 23px);

  @mixin media 551 {
    max-width: 312px;
  }

  .swiper-slide {
    background: var(--light-blue);
    border-radius: 16px;

    &:not(&.swiper-slide-active) {
      .teammate-testimonials__card {
        opacity: 0;
      }
    }
  }
}

.teammate-testimonials__card {
  padding: ac(48px, 24px);
  border: 1px solid var(--light-gray);
  border-radius: 16px;

  box-shadow: 0 0 16px rgba(0, 0, 0, 0.12);
  background: var(--white);

  transition: setTransition(opacity);

  @mixin media 769 {
    padding: 24px 8px 8px;
  }
}

.teammate-testimonials__card-logo-wrap {
  display: flex;
  align-items: center;

  margin-bottom: ac(32px, 18px);

  @mixin media 769 {
    justify-content: space-evenly;
  }

  &::before,
  &::after {
    content: "";

    display: block;

    flex-grow: 1;
    height: 1px;

    background: var(--light-gray);

    @mixin media 769 {
      max-width: 40px;
    }
  }

  &::before {
    margin-right: 16px;
  }
  &::after {
    margin-left: 16px;
  }
}

.teammate-testimonials__card-logo {
  width: 205px;
  height: auto;

  @mixin media 769 {
    width: 122px;
  }
}

.teammate-testimonials__card-inner-wrap {
  position: relative;
  overflow: hidden;

  padding: ac(37px, 24px) ac(45px, 24px);
  border-radius: 8px;

  color: var(--white);
  background: linear-gradient(139.64deg, #b919aa 24.08%, #46227c 117.07%),
    #ffffff;

  @mixin media 551 {
    padding: 24px 8px 32px 24px;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;

    width: 108px;
    height: 96px;

    display: block;

    background-size: contain;
    background-image: url("/images/quotes.svg");
    background-repeat: no-repeat;
    opacity: 0.1;
  }

  &::before {
    left: ac(-8px, -2px);
    top: ac(-16px, -6px);

    transform: rotate(180deg);
  }

  &::after {
    right: ac(-8px, -2px);
    bottom: ac(-16px, -6px);
  }
}

.teammate-testimonials__card-teammate-info {
  display: flex;
  align-items: center;

  margin-bottom: ac(25px, 20px);
}

.teammate-testimonials__card-photo-wrap {
  width: ac(104px, 85px);
  height: ac(104px, 85px);
  flex-shrink: 0;

  margin-right: ac(20px, 14px);
  padding: ac(6px, 2px);
  border-radius: 50%;

  background: var(--white);

  @mixin media 551 {
    margin-left: -5px;
  }
}

.teammate-testimonials__card-photo {
  border-radius: 50%;
}

.teammate-testimonials__card-name {
  margin-bottom: 6px;

  font-size: ac(24px, 22px);
  font-weight: 700;
}

.teammate-testimonials__card-position {
  max-width: 178px;

  font-size: ac(16px, 14px);
  font-weight: 500;
  line-height: 1.48;

  @mixin media 769 {
    line-height: 1.2;
  }
}

.teammate-testimonials__card-text {
  height: 200px;

  margin-bottom: ac(38px, 27px);

  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;

  * {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6;
    color: var(--white);
  }

  .simplebar-scrollbar {
    &::before {
      background: var(--light-gray) !important;
    }
  }
}

.teammate-testimonials__card-btn {
  width: 100%;
  max-width: 200px;
}

.teammate-testimonials-swiper__nav {
  display: flex;
  align-items: center;
  justify-content: center;
}

.teammate-testimonials-swiper__pagination {
  width: fit-content;

  margin: 0 ac(24px, 21px);

  font-size: ac(24px, 16px);
  font-weight: 400;
  line-height: 1.34;
}

.teammate-testimonials-swiper__btn {
  color: var(--magenta);

  font-size: ac(24.5px, 15px);
  line-height: 1;
}
