.footer {
  padding-bottom: 16px;
}

.footer__main {
  display: flex;
  /* justify-content: space-between; */

  padding: ac(31px, 27px) 0 19px;

  @mixin media 1281 {
    flex-direction: column-reverse;
  }
}

.footer__contacts {
  display: flex;
  align-items: center;

  margin-right: ac(40px, 20px);

  @mixin media 1281 {
    justify-content: center;

    margin-right: 0;
    margin-top: 24px;
  }

  @mixin media 901 {
    flex-direction: column;
    align-items: flex-start;

    max-width: 330px;

    margin: 24px auto 0;
  }
}

.footer__contact-item {
  display: flex;
  align-items: center;

  height: fit-content;

  &:not(:last-child) {
    margin-right: 39px;

    @mixin media 901 {
      margin-right: 0;
      margin-bottom: 14px;
    }
  }

  &:not(:first-child) {
    padding-left: 8px;
    border-left: 1px solid var(--light-gray);

    @mixin media 901 {
      padding-left: 0;
      border-left: none;
    }
  }

  &:last-child {
    padding-right: 40px;
    border-right: 1px solid var(--light-gray);

    @mixin media 1281 {
      padding-right: 0;
      border-right: none;
    }
  }

  i {
    margin-right: 8px;

    background: var(--magenta-gr);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font-size: 32px;
  }
}

.footer__contact-item-text {
  max-width: 183px;

  font-size: 14px;
  font-weight: 400;

  transition: setTransition(color);

  a&:hover {
    color: var(--magenta);
  }

  @mixin media 901 {
    max-width: 214px;

    font-size: 16px;
    line-height: 1.6;
  }
}

.footer__socials {
  display: flex;
  align-items: center;
  flex-shrink: 0;

  @mixin media 1281 {
    justify-content: center;
  }

  @mixin media 901 {
    border-bottom: 1px solid var(--light-gray);
    padding-bottom: 24px;
  }

  @mixin media 551 {
    position: relative;
    left: 8px;
  }

  @mixin media 350 {
    left: unset;
  }
}

.footer__socials-text {
  margin-right: 24px;

  font-size: 16px;

  @mixin media 551 {
    margin-right: 30px;
  }

  @mixin media 350 {
    margin-right: 24px;

    font-size: 14px;
  }
}

.footer__socials-list-item {
  &:not(:last-child) {
    margin-right: 16px;

    @mixin media 350 {
      margin-right: 10px;
    }
  }
}

.footer__socials-list {
  display: flex;
}

.footer__socials-list-link {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border: 1px solid var(--light-gray);
  border-radius: 50%;

  font-size: 13px;

  transition: setTransition(color);

  @mixin media 901 {
    width: 40px;
    height: 40px;

    font-size: 16px;
  }

  i {
    position: relative;
    z-index: 1;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    border-radius: 50%;

    background: var(--magenta-gr);

    opacity: 0;
    transform: scale(0.8);

    transition: setTransition(400ms, opacity, transform);
  }

  &:hover {
    color: var(--white);

    &::before {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.footer__bottom {
  padding: ac(20px, 10px);
  border-top: 1px solid var(--light-gray);

  color: var(--gray);
  font-size: 12px;
  font-weight: 500;
  text-align: center;

  @mixin media 901 {
    width: 89%;
    margin: 0 auto;
  }
}
