/* ----------------------- Common desktop header ----------------------- */
.header {
  position: absolute;
  transition: background ease-in-out 0.25s, height ease-in-out 0.25s;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  height: var(--header-h);
  padding: ac(25px, 15px) 0;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 8px 0px #0000000f;

  background: var(--white);

  &.absolute {
    position: absolute;
  }

  &.fixed {
    position: fixed;
  }

  &.scrolled {
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(13px);
    height: var(--scrolled-header-h);

    .logo {
      width: ac(130px, 100px);
    }
  }

  .logo {
    height: 100%;
    width: ac(163px, 153px);
    flex-shrink: 0;

    transition: setTransition(transform, width);

    @mixin media 501 {
      margin-right: 15px;
    }

    @mixin media 360 {
      width: 120px;
    }

    &::before {
      display: none;
    }

    &:hover {
      transform: scale(1.06);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .navbar {
    display: grid;
    justify-content: space-between;
    align-items: center;
    grid-template-columns: 1fr 2fr 1fr;
  }

  .header__right-block {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .header__search-wrap {
    position: relative;

    margin-right: ac(20px, 8px);

    &.active {
      .header__search-input {
        opacity: 1;
        transform: translateX(0);
        visibility: visible;
      }

      .header__search-btn {
        .icon-search {
          display: none;
        }
        .icon-close {
          display: block;
        }
      }
    }
  }

  .header__search-inner-wrap {
    &:focus-within {
      .header__search-result {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .header__search-btn {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);

    display: flex;

    padding-left: 5px;

    font-size: 18px;

    transition: setTransition(color);

    i {
      line-height: unset;
    }

    .icon-close {
      display: none;
    }

    &:hover {
      color: var(--magenta);
    }
  }

  .header__search-input {
    width: 100%;

    padding: 5px 25px 5px 10px;
    border: 1px solid var(--black);
    border-radius: 4px;

    background: transparent;

    opacity: 0;
    transform: scaleX(0.5);
    transform-origin: right center;
    visibility: hidden;

    transition: setTransition(opacity, transform, visibility);

    &::placeholder {
      color: var(--black);
    }
  }

  .btn.header__talk-btn {
    cursor: pointer;

    @mixin media 1281 {
      min-width: fit-content;

      padding: 8px ac(30px, 20px, 1024, 1281);
    }

    @mixin media 1025 {
      display: none;
    }
  }

  .header__search-result {
    position: absolute;
    top: calc(100% + 8px);
    left: 0;

    width: 100%;
    max-height: 125px;

    border-radius: 8px;
    padding-right: 10px;
    padding-left: 5px;

    background: var(--white);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    opacity: 0;
    visibility: hidden;

    transition: setTransition(opacity, visibility);
  }

  .header__search-item {
    @mixin max-line-length 2;

    width: 100%;

    padding: 5px;

    &:not(:last-child) {
      border-bottom: 1px solid var(--light-gray);
    }

    transition: setTransition(color);

    &:hover {
      color: var(--magenta);
    }
  }

  .burger {
    display: none;
  }

  .menu {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .menu-item {
    margin-right: ac(20px, 10px, 1025);
    position: relative;

    &.dropdown {
      & > .menu-link {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;

        &::after {
          content: "\e999";
          font-family: "icomoon";
          font-size: ac(10px, 8px);
          padding-left: 10px;
        }
      }
    }
  }

  .header__mob-socials {
    display: none;

    margin-bottom: 30px;

    @mixin media 1025 {
      display: flex;
    }
  }

  .header__mob-socials-item {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  .header__mob-socials-link {
    position: relative;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 40px;
    height: 40px;

    border: 1px solid var(--light-gray);
    border-radius: 50%;

    font-size: 17px;

    transition: setTransition(400ms, color, transform);

    i {
      position: relative;
      z-index: 1;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      border-radius: 50%;

      background: var(--magenta-gr);

      opacity: 0;
      transform: scale(0.8);

      transition: setTransition(400ms, opacity, transform);
    }

    &:hover {
      color: var(--white);

      &::before {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
  .header__talk-btn-mob {
    margin-top: auto;

    width: 100%;
    max-width: 350px;
    height: 40px;
  }

  &:not(.header-mobile) {
    .menu-item {
      @media (--mobile-menu-end-point) {
        padding: 5px 0;
      }

      &:hover {
        @media (--mobile-menu-end-point) {
          .dropdown-menu {
            display: block;
          }
        }
      }
    }
  }

  .menu-link {
    position: relative;
    font-size: ac(16px, 14px);
    font-weight: 500;
    /* for fnt bag fix */
    vertical-align: baseline;
    transition: setTransition(color);
    text-transform: capitalize;

    cursor: pointer;

    &:hover {
      color: var(--magenta);
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    padding: 10px 10px 10px 15px;
    border-radius: 10px;
    background: var(--bg-second);
    box-sizing: content-box;
    display: none;

    li {
      .menu-link {
        padding: 5px 0;
        text-transform: capitalize;
      }
    }
  }
}

/* end of Common desktop header */

/* ----------------------- Common desktop header Mobile HEADER ----------------------- */

@media (--mobile-menu-start-point) {
  .header:not(.header-mobile) {
    .burger,
    .menu-toggle {
      display: flex;
    }

    .navbar {
      grid-template-columns: auto 1fr;
    }

    .navbar-nav {
      position: fixed;
      right: 0;
      top: var(--header-h);
      overflow: auto;

      display: flex;
      flex-direction: column;

      width: 100%;
      max-width: 400px;
      height: calc(var(--vh, 1vh) * 100 - var(--header-h));
      min-width: 320px;

      border-top: 1px solid var(--light-gray);
      padding: 58px 24px 24px;

      background: var(--white);

      transition: transform 0.3s ease-out;

      @mixin media 551 {
        max-width: 100%;
      }

      &.nav-slide-left {
        right: auto;
        left: 0;
        transform: translateX(-100%);

        &.active {
          transform: translateX(0);
        }
      }

      &.nav-slide-right {
        right: 0;
        left: auto;
        transform: translateX(100%);

        &.active {
          transform: translateX(0);
        }
      }

      &.nav-slide-down {
        right: auto;
        left: 0;
        transform: translateY(-100%);

        &.active {
          transform: translateY(0);
        }
      }

      &.nav-slide-up {
        right: auto;
        left: 0;
        transform: translateY(100%);

        &.active {
          transform: translateY(0);
        }
      }

      .menu {
        flex-grow: 1;
        overflow: auto;
        height: 0;

        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        width: 100%;
        max-height: 100%;
        /* padding-left: 40px; */

        margin-bottom: 43px;

        &::-webkit-scrollbar {
          width: 3px;
          height: 2px;
          background: rgba(255, 255, 255, 0.1);
          border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
          background: rgba(255, 255, 255, 0.5);
          border-radius: 3px;
          cursor: pointer;
        }
      }

      .menu-link {
        display: flex;
        align-items: center;

        height: 43px;

        margin-bottom: 21px;

        font-size: 24px;
      }
    }

    &.scrolled {
      .navbar-nav {
        top: var(--scrolled-header-h);
        /* height: calc(100vh - var(--scrolled-header-h)); */
        height: calc(var(--vh, 1vh) * 100 - var(--scrolled-header-h));
      }
    }

    .dropdown-menu {
      position: relative;
      top: var(--header-h);
    }
  }
}

/* end of Common desktop header Mobile HEADER */

/* ----------------------- HEADER COMPONENTS ----------------------- */
.burger,
.menu-toggle {
  position: relative;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  display: flex;
  margin-right: -10px;

  @mixin min-media 1025 {
    &:hover {
      transform: scale(1.1);
    }
  }

  &:hover {
    .line {
      stroke: var(--magenta);
    }
  }

  &.active {
    .hamRotate {
      transform: rotate(45deg);

      .line {
        &.top {
          stroke-dashoffset: -98px;
        }
        &.bottom {
          stroke-dashoffset: -138px;
        }
      }
    }
  }
}

.header-close-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: var(--header-h);
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 77;
  background: rgba(0, 0, 0, 0.7);
  pointer-events: none;
  visibility: hidden;
  opacity: 0;

  @mixin transition-all;

  &.active {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    cursor: pointer;
  }

  &.scrolled {
    top: var(--scrolled-header-h);
  }
}

/* end of HEADER COMPONENTS */

.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.line {
  fill: none;
  stroke: var(--black);
  stroke-width: 3;
  stroke-linecap: round;

  transition: setTransition(400ms, stroke, stroke-dasharray, stroke-dashoffset);
}
.ham1 .top {
  stroke-dasharray: 40 139;
}
.ham1 .bottom {
  stroke-dasharray: 40 180;
}

.menu-dropdown-link {
  @media (hover: hover) {
    &:hover {
      .menu-dropdown {
        opacity: 1;
        visibility: visible;
      }

      .menu-dropdown-text {
        i {
          transform: rotate(180deg);
        }
      }
    }
  }

  &.hovered {
    .menu-dropdown {
      opacity: 1;
      visibility: visible;
    }

    .menu-dropdown-text {
      i {
        transform: rotate(180deg);
      }
    }
  }

  @mixin media 1025 {
    flex-direction: column;
    align-items: start !important;

    height: unset !important;

    margin-bottom: 0 !important;

    @media (hover: hover) {
      &:hover {
        .menu-dropdown {
          max-height: 1000px;
        }
      }
    }

    &.hovered {
      .menu-dropdown {
        max-height: 1000px;
      }
    }
  }
}

.menu-dropdown {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);

  width: max-content;

  border-radius: 8px;
  padding: 10px;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.059);
  background: var(--white);

  opacity: 0;
  visibility: hidden;

  transition: setTransition(opacity, visibility);

  @mixin media 1025 {
    position: static;
    overflow: hidden;
    transform: none;
    max-height: 0;

    border-radius: 0;
    padding: 0;

    box-shadow: none;

    transition: setTransition(400ms, max-height, opacity, visibility);
  }
}

.menu-dropdown-text {
  display: flex;
  align-items: center;

  i {
    position: relative;
    /* top: 1px; */

    display: block;

    margin-left: 5px;

    font-size: 10px;

    transition: setTransition(transform);
  }

  @mixin media 1025 {
    margin-bottom: 21px;

    i {
      font-size: 14px;
      margin-left: 10px;
    }
  }
}

.menu-dropdown-item {
  .menu-link {
    width: 100%;
    padding: 5px 20px;
    text-align: center;

    @mixin media 1025 {
      padding: 0 0 0 20px;
    }
  }
}
