.first-section {
  &.inner-first-section {
    .who-we-img {
      size: ac(624px, 280px);
    }
    .who-we-bg {
      bottom: ac(-168px, -50px);
    }
  }
}

.who-we-are {
  overflow: hidden;

  padding: ac(80px, 40px) 0 ac(128px, 48px);

  &__wrap {
    display: flex;
    align-items: center;
  }

  &__content {
    border: 1px solid var(--light-gray);
    border-radius: 16px;
    padding: ac(48px, 24px) ac(62px, 24px);
    background-color: var(--light-blue);
    max-width: 642px;
    margin-right: 40px;

    &-title {
      margin-bottom: 24px;
    }

    &-subtitle {
      max-width: 440px;
      font-size: 20px;
      margin-bottom: 24px;
    }

    .btn {
      min-width: 222px;
    }

    &-text {
      border-top: 1px solid var(--light-gray);
      padding: 48px 0 24px;

      font-size: 16px;
      line-height: 163%;

      p {
        font-size: 16px;
        line-height: 163%;
      }
    }
  }

  &__gallery {
    max-height: 618px;
    max-width: 618px;
    position: relative;
    width: 50%;

    .gallery-swiper__top-wrap {
      background: var(--white);
      border: 1px solid var(--light-gray);
      border-radius: 16px;
      position: absolute;
      top: 0;
      z-index: 5;
    }

    .gallery-swiper-img {
      border-radius: 16px;
    }
  }

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 81.4vw;
    height: 1px;
    background: var(--light-gray);
    bottom: ac(64px, 24px);
  }

  @mixin media 1150 {
    &__wrap {
      flex-direction: column;
    }

    &__gallery,
    &__content {
      max-width: 100%;
      width: 100%;
    }

    &__content {
      margin: 0 0 64px;
    }
  }
}

.history {
  overflow: hidden;

  padding: ac(80px, 24px) 0 ac(114px, 58px);
  background-color: var(--light-blue);
  margin-bottom: ac(80px, 40px);

  &__top-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ac(63px, 25px);
  }
}

.history-swiper {
  width: 464px;
  position: relative;

  @mixin media 551 {
    width: 100%;
  }

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-slide {
    overflow: visible;

    &.swiper-slide-active {
      .history-card__vector {
        transform: translate(-50%, 0);
        opacity: 0.5;
      }
    }

    @mixin min-media 769 {
      &:not(.swiper-slide-active, .swiper-slide-next) {
        .history-card__preview {
          transform: translate(0, -50%) scale(0.7);
          left: 0;
        }
      }

      .history-card__inner {
        opacity: 0;
      }

      .history-card__preview {
        display: flex;
      }

      &.swiper-slide-active {
        .history-card__inner {
          opacity: 1;
        }

        .history-card__preview {
          opacity: 0;
        }
      }
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100vw;
    height: 2px;
    background: var(--magenta-gr);
  }
}

.history-card {
  position: relative;
  max-width: 464px;

  &__inner {
    padding: 6px;
    background: var(--white);
    border-radius: 12px;
    transition: 0.5s ease;
    opacity: 1;
    position: relative;
    z-index: 5;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.08);
  }

  &__preview {
    background: linear-gradient(310.83deg, #2afacf 16.95%, #2f4596 109.17%),
      #ffffff;
    font-size: 24px;
    font-weight: 700;
    color: var(--white);
    border-radius: 8px;

    display: none;
    align-items: center;
    justify-content: center;

    width: 150px;
    height: 64px;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease;
    cursor: pointer;
    z-index: 10;
  }

  &__top-wrap {
    display: flex;
    align-items: center;

    background: linear-gradient(310.83deg, #2afacf 16.95%, #2f4596 109.17%),
      #ffffff;
    padding: 14px 42px;
    border-radius: 12px;

    @mixin media 551 {
      padding: 24px;
    }
  }

  &__logo {
    size: ac(40px, 32px);
    margin-right: 16px;
  }

  &__year {
    font-weight: 700;
    font-size: ac(36px, 24px);
    line-height: 134%;
    color: var(--white);
  }

  &__content {
    padding: 0 ac(30px, 24px) ac(42px, 32px) ac(40px, 24px);

    &-title {
      font-size: ac(24px, 22px);
      margin: ac(30px, 16px) 0 ac(28px, 8px);
    }

    &-text {
      max-height: 205px;
      padding-right: 20px;

      p {
        line-height: 160%;
      }
    }
  }

  &__vector {
    position: absolute;
    /* bottom: -40px; */
    bottom: 0;
    width: 76%;
    height: 163px;
    z-index: -1;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: 0.5s ease;
    pointer-events: none;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    @mixin media 415 {
      width: 60%;
      bottom: -65px;
    }
  }

  @mixin tab-sm {
  }
}

/* Giving back section */
.giving-back {
  margin-bottom: ac(64px, 32px);

  .cont {
    padding-bottom: ac(64px, 32px);
    border-bottom: 1px solid var(--light-gray);
  }

  &__wrap {
    display: flex;
    align-items: stretch;

    @mixin media 769 {
      flex-direction: column;
      align-items: center;
    }
  }

  &__info,
  &__logos {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    width: 100%;
  }

  &__info {
    background: var(--white);
    border: 1px solid var(--light-gray);
    max-width: 650px;
    padding: ac(53px, 24px) ac(48px, 24px) ac(56px, 32px);

    position: relative;
    z-index: 5;

    &-title {
      font-size: ac(48px, 26px);
      margin-bottom: 24px;
    }

    &-text {
      max-width: 476px;

      p {
        font-size: ac(20px, 18px);
        line-height: 160%;
      }
    }
  }

  &__logos {
    background: var(--light-blue);
    max-width: 701px;
    margin-left: -43px;
    padding: 40px 0 55px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &-item {
      width: 100%;
      padding: 0 32px;

      img {
        margin: 0 auto;

        @mixin media 1200 {
          width: 50%;
        }

        @mixin media 769 {
          width: auto;
        }
      }

      &:not(:last-child) {
        padding-bottom: 32px;
        border-bottom: 2px solid var(--white);
        margin-bottom: 32px;
      }
    }

    @mixin media 1200 {
      justify-content: space-around;
    }
  }

  @mixin media 769 {
    &__info,
    &__logos {
      max-width: 100%;
    }

    &__logos {
      margin: 8px 0 0;
    }
  }
}

.similar-blogs__title {
  font-size: ac(38px, 26px);
}

.quote-section {
  padding: ac(128px, 48px) 0 ac(80px, 56px);

  &:before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 81.4vw;
    height: 1px;
    background: var(--light-gray);
    top: ac(64px, 24px);
  }
}

.quote-block {
  border-radius: 16px;
  background-color: var(--light-blue);

  display: flex;
  align-items: center;
  justify-content: center;

  padding: ac(64px, 24px) 24px ac(84px, 32px);

  &__logo {
    size: ac(177px, 64px);
    margin-right: 24px;
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__text {
    max-width: 887px;
    line-height: 134%;
    font-weight: 700;
    font-size: ac(48px, 22px);
  }

  @mixin media 551 {
    flex-direction: column;

    &__logo {
      margin: 0 0 16px;
    }

    &__text {
      text-align: center;
    }
  }
}

/* Synchro values */
.synchro-values {
  overflow: hidden;

  padding-top: 32px;
  padding-bottom: 20px;

  .cont {
    position: relative;

    padding-top: 64px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%);

      width: 100%;
      max-width: 1172px;
      height: 1px;

      background: var(--light-gray);
    }
  }

  .values-swiper {
    width: 100%;
  }

  .swiper-slide {
    max-width: 313px;
  }

  @mixin media 769 {
    .cont {
      padding-top: 0;

      &::before {
        display: none;
      }
    }
  }

  &.no-line {
    padding-top: 0;

    .cont {
      padding-top: 0;

      &::before {
        display: none;
      }
    }
  }

  &__top-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ac(64px, 32px);

    .section__title {
      margin: 0;
      margin-right: 20px;
    }
  }
}

.values-card {
  background: var(--white);
  padding: ac(32px, 24px) ac(24px, 20px) ac(37px, 32px);
  border: 1px solid var(--light-gray);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__img {
    size: ac(128px, 104px);
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    &-icon {
      background: var(--white);
      border-radius: 50%;
      border: 2px solid #f6f5f1;
      position: relative;
      z-index: 5;
      size: ac(112px, 88px);

      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;

      img {
        size: 74%;
        object-fit: contain;
      }
    }

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: url("../images/value-elipse-bg.svg") no-repeat center /
        contain;
    }
  }

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;

    min-height: 64px;

    margin: ac(27px, 18px) 0 9px;

    font-size: ac(24px, 22px);
    line-height: 134%;
  }

  &__descr {
    max-width: 225px;
    width: 100%;

    max-height: 112px;
    padding-right: 20px;
    margin-right: -20px;

    p {
      font-size: 14px;
      line-height: 160%;
    }
  }
}
